import {useState, useEffect} from 'react';
import './Chart.css';
import {Line} from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import * as zoom from 'chartjs-plugin-zoom';
import {useSelector} from "react-redux";

const borderColors = ["#191716", "#E6AF2E", "#E0E2DB", "#BEB7A4"];

/*function Chart (props) {
    let verticalLines = [];
    let dataLabel = "";
    const [quote, setQuote] = useState(0);
    const [high, setHigh] = useState(0);
    const [low, setLow] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [state, setState] = useState({datasets: []});

    const [chartBounds, setChartBounds] = useState([0, 0]);
    const [symbol, setSymbol] = useState("TSLA");
    const [strategies, setStrategies] = useState([]);


    useEffect(() => {
/!*        async function getTickerData(tick) {
            let response = await fetch('https://cloud.iexapis.com/stable/stock/' + tick + '/quote?token=' + process.env.REACT_APP_IEX_API_KEY);
            let data = await response.json();
            return data;
        }*!/
        setHigh(298);
        setLow(220);
        setQuote(272);
        setStrategies([
            {
                strat: 'Long Straddle',
                legs: {
                    '0': {
                        direction: '+',
                        type: 'C',
                        strike: '275',
                        premium: '2',
                        quantity: '1'
                    },
                    '1': {
                        direction: '+',
                        type: 'P',
                        strike: '295',
                        premium: '3',
                        quantity: '1'
                    }
                }
            },
            {
                strat: 'Bear Put Spread',
                legs: {
                    '0': {
                        direction: '+',
                        type: 'P',
                        strike: '240',
                        premium: '4',
                        quantity: '1'
                    },
                    '1': {
                        direction: '-',
                        type: 'P',
                        strike: '250',
                        premium: '3',
                        quantity: '1'
                    }
                }
            }
        ]);
        /!*if (symbol !== undefined && symbol !== ''){
            getTickerData(symbol).then(data => {
                //TODO: Add y calculation here as well
                setHigh(data.week52High);
                setLow(data.week52Low);
                setQuote(data.latestPrice);
            });
        }*!/

    }, []);

    let lower_bound = Math.ceil(low - quote/10 - 10);
    let upper_bound = Math.ceil(high + quote/10 + 10);
    useEffect(() => {
        let chart_data = [];
        let strikes = [];
        for (let i = 0; i < strategies.length; i++){
            for (let j = 0; strategies[i].legs !== undefined && j < Object.keys(strategies[i].legs).length; j++){
                if (Number(strategies[i].legs[j].strike) + quote/10 > upper_bound){
                    upper_bound = Number(strategies[i].legs[j].strike) + quote/10;
                }
                strikes.push(strategies[i].legs[j].strike);
            }
        }

        for (let i = 0; i < strategies.length; i++){
            chart_data.push([]);
            if (low + high + quote !== 0){
                let cur_x = lower_bound;
                while (cur_x <= upper_bound){
                    chart_data[i].push({x: Number(cur_x).toFixed(2), y: 0});
                    strikes.forEach((strike) => {
                        if (strike > cur_x && strike < cur_x + 10){
                            chart_data[i].push({x: strike, y: 0 });
                        }
                    });
                    cur_x += 10;
                }
            }
            for (let j = 0; strategies[i].legs !== undefined && j < Object.keys(strategies[i].legs).length; j++){
                let factor = strategies[i].legs[j].direction === "-" ? 1 : -1
                let type = strategies[i].legs[j].type;
                let premium = strategies[i].legs[j].premium * 100;
                let strike = strategies[i].legs[j].strike;
                let quantity = strategies[i].legs[j].quantity;
                for (let k = 0; k < chart_data[i].length; k++){
                    let x = parseFloat(chart_data[i][k].x);

                    let cur_y = factor * premium;

                    // Handle calls
                    if (type === "C" && x >= strike){
                        cur_y = - factor * ((x - strike)*100 - premium);
                    }

                    // Handle puts
                    if (type === "P" && x <= strike){
                        cur_y = -factor * ((strike - x)*100 - premium)
                    }
                    if (premium !== 0 && strike !== 0 && premium !== undefined && strike !== undefined){
                        chart_data[i][k].y += cur_y * quantity;
                    }
                    console.log("post y chart data", chart_data);
                }
            }
        }
        if (strategies.length == 0){
            setChartData([]);
        }
        else if (strategies[0].legs === undefined){
            setChartData([]);
        }
        else {
            setChartData(chart_data);
        }


    }, [strategies])


    useEffect(() => {
    if (high !== 0){
        const intQuote = Math.floor(quote / 5) * 5;
        let third = (quote - intQuote) * 100 - 150;
        if (quote < intQuote) {
            third = -150;
        }
        dataLabel = "Long " + intQuote + " call @ 1.50";
        verticalLines = [
            {
                type: "line",
                mode: "vertical",
                scaleID: "x-axis-0",
                value: Number(low).toFixed(2),
                borderColor: "#38677D",
                borderWidth: 2,
                borderDash: [2],

                label: {
                    backgroundColor: "#959296",
                    content: "52 wk Low",
                    position: "bottom",
                    enabled: true
                }
            },
            {
                type: "line",
                mode: "vertical",
                scaleID: "x-axis-0",
                value: Number(quote).toFixed(2),
                borderColor: "#38677D",
                borderWidth: 2,
                borderDash: [2],

                label: {
                    backgroundColor: "#959296",
                    content: "Current Price",
                    enabled: true
                }
            } ,
            {
                type: "line",
                mode: "vertical",
                scaleID: "x-axis-0",
                value: Number(high).toFixed(2),
                borderColor: "#38677D",
                borderWidth: 2,
                borderDash: [2],

                label: {
                    backgroundColor: "#959296",
                    content: "52 wk High",
                    position: "top",
                    enabled: true
                }
            }
        ];
    }
        }, [high]);

    useEffect(() => {
    for (let i = 0; i < chartData.length; i++){
        setState({
            datasets: [({
                label: "Strategy " + (i + 1),
                fill: false,
                lineTension: 0,
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: borderColors[i],
                borderWidth: 2,
                data: chartData[i],
            })]
        });
    }
    }, [chartData]);

    return (
        <div className="Chart-div">
            <Line
                data={state}
                options={{
                    title:{
                        display:true,
                        text: (symbol === undefined ? "" : symbol) + " Profit/Loss Chart",
                        fontSize:20
                    },
                    legend:{
                        display:true,
                        position:'right'
                    },
                    scales:{
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Profit or Loss',
                                fontSize: 18
                            },
                            ticks: {
                                callback: function(value, index, values) {
                                    return '$' + value;
                                }
                            }
                        }],
                        xAxes: [{
                            type: 'linear',
                            scaleLabel: {
                                display: true,
                                labelString: 'Price at Expiration',
                                fontSize: 18
                            },
                            ticks: {
                                callback: function(value, index, values) {
                                    return '$' + value;
                                },
                                min: Math.max(0, lower_bound),
                                max: upper_bound,
                            },
                        }],
                    },
                    plugins: {
                        zoom: {
                            // Container for pan options
                            pan: {
                                // Boolean to enable panning
                                enabled: true,

                                // Panning directions. Remove the appropriate direction to disable
                                // Eg. 'y' would only allow panning in the y direction
                                mode: 'xy'
                            },

                            // Container for zoom options
                            pinch: {
                                // Boolean to enable zooming
                                enabled: false,

                                // Zooming directions. Remove the appropriate direction to disable
                                // Eg. 'y' would only allow zooming in the y direction
                                mode: 'xy',
                            },
                            // Container for zoom options
                            wheel: {
                                // Boolean to enable zooming
                                enabled: false,

                                // Zooming directions. Remove the appropriate direction to disable
                                // Eg. 'y' would only allow zooming in the y direction
                                mode: 'xy',
                            }
                        }
                    },
                    annotation: {
                        drawTime: 'beforeDatasetsDraw',
                        annotations: verticalLines,
                    },
                }}
            />
        </div>
    );
}*/


const Chart = () => {
    let verticalLines = [];
    let dataLabel = "";
    const [quote, setQuote] = useState(0);
    const [high, setHigh] = useState(0);
    const [low, setLow] = useState(0);
    const [strategies, setStrategies] = useState([]);
    const [symbol, setSymbol] = useState("TSLA");
    const [lower_bound, setLower_bound] = useState(0);
    const [upper_bound, setUpper_bound] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [state, setState] = useState({datasets: []});
    const [chartBounds, setChartBounds] = useState([0, 0]);
    const useChart = useSelector((state) => ({
        isChartLoading: state.notificationsState.isChartLoading,
        optionStrategies: state.notificationsState.optionStrategies,
        tickerInfo: state.notificationsState.tickerInfo,
    }));

    const {
        isChartLoading,
        optionStrategies,
        tickerInfo,
    } = useChart;

    //update optionStrategies High Low and Quote for symbol
    useEffect(() => {
        if (optionStrategies.symbol !== undefined && optionStrategies.symbol !== '') {
            //TODO: Add y calculation here as well
            setHigh(tickerInfo.yearHigh);
            setLow(tickerInfo.yearLow);
            setQuote(tickerInfo.spotPrice);
            setLower_bound(Math.ceil(low - quote / 10 - 10));
            setUpper_bound(Math.ceil(high + quote / 10 + 10));
            //update verticalLines
            if (high > 0) {
                const intQuote = Math.floor(quote / 5) * 5;
                let third = (quote - intQuote) * 100 - 150;
                if (quote < intQuote) {
                    third = -150;
                }
                dataLabel = "Long " + intQuote + " call @ 1.50";
                verticalLines = [{
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: Number(low).toFixed(2),
                    borderColor: "#38677D",
                    borderWidth: 2,
                    borderDash: [2],

                    label: {
                        backgroundColor: "#959296", content: "52 wk Low", position: "bottom", enabled: true
                    }
                }, {
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: Number(quote).toFixed(2),
                    borderColor: "#38677D",
                    borderWidth: 2,
                    borderDash: [2],

                    label: {
                        backgroundColor: "#959296", content: "Current Price", enabled: true
                    }
                }, {
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: Number(high).toFixed(2),
                    borderColor: "#38677D",
                    borderWidth: 2,
                    borderDash: [2],

                    label: {
                        backgroundColor: "#959296", content: "52 wk High", position: "top", enabled: true
                    }
                }];
            }
        }
        setSymbol(optionStrategies.symbol);
        let optionStratArray = ([{
            strat: optionStrategies.strategyName, legs: optionStrategies.legs,
        }]);
        setStrategies(optionStratArray);
    }, [tickerInfo]);
    //update chartData based on strategies updated
    useEffect(() => {
        let chart_data = [];
        let strikes = [];
        let lower_bound = Math.ceil(low - quote / 10 - 10);
        let upper_bound = Math.ceil(high + quote / 10 + 10);
        for (let i = 0; i < strategies.length; i++) {
            for (let j = 0; strategies[i].legs !== undefined && j < Object.keys(strategies[i].legs).length; j++) {
                if (Number(strategies[i].legs[j].strike) + quote / 10 > upper_bound) {
                    upper_bound = Number(strategies[i].legs[j].strike) + quote / 10;
                }
                strikes.push(strategies[i].legs[j].strike);
            }
        }
        //calculate x axis
        for (let i = 0; i < strategies.length; i++) {
            chart_data.push([]);
            if (low + high + quote !== 0) {
                let cur_x = lower_bound;
                while (cur_x <= upper_bound) {
                    chart_data[i].push({x: Number(cur_x).toFixed(2), y: 0});
                    strikes.forEach((strike) => {
                        if (strike > cur_x && strike < cur_x + 10) {
                            chart_data[i].push({x: strike, y: 0});
                        }
                    });
                    cur_x += 10;
                }
            }
            //console.log("chart data pre y axis", chart_data);
            //calculate y axis
            for (let j = 0; strategies[i].legs !== undefined && j < Object.keys(strategies[i].legs).length; j++) {
                let factor = optionStrategies.legs[j].direction === process.env.REACT_APP_TRADE_DIRECTION_SHORT ? 1 : -1
                let type = optionStrategies.legs[j].type === process.env.REACT_APP_OPTION_TYPE_CALL ? "C" : "P";
                let premium = strategies[i].legs[j].premium * 100;
                let strike = strategies[i].legs[j].strike;
                let quantity = strategies[i].legs[j].quantity;
                //console.log("this is chart data pre y axis", chart_data);
                for (let k = 0; k < chart_data[i].length; k++) {
                    let x = parseFloat(chart_data[i][k].x);
                    let cur_y = factor * premium;
                    // Handle calls
                    if (type === "C" && x >= strike) {
                        cur_y = -factor * ((x - strike) * 100 - premium);
                    }
                    // Handle puts
                    if (type === "P" && x <= strike) {
                        cur_y = -factor * ((strike - x) * 100 - premium)
                    }
                    if (premium !== 0 && strike !== 0 && premium !== undefined && strike !== undefined) {
                        //console.log("cur_y ----", cur_y, strike, premium);
                        chart_data[i][k].y += cur_y * quantity;
                    }
                }
                //console.log("this is chart data post y axis", chart_data);
            }
        }
        if (strategies.length === 0) {
            setChartData([]);
        } else if (strategies[0].legs === undefined) {
            setChartData([]);
        } else {
            setChartData(chart_data);
        }
    }, [strategies])

    //update dataset to be fed to line graph
    useEffect(() => {
        //console.log("this is chart data", chartData);
        for (let i = 0; i < chartData.length; i++) {
            setState({
                datasets: [({
                    label: "Strategy " + optionStrategies.strategyName + " for " + symbol,
                    fill: false,
                    lineTension: 0,
                    backgroundColor: 'rgba(75, 192, 192, 1)',
                    borderColor: borderColors[i],
                    borderWidth: 2,
                    data: chartData[i],
                })]
            });
        }
    }, [chartData]);
    console.log("this is optionsstrategies", optionStrategies);
    return (isChartLoading ? <div className="Chart-div">Loading please wait...</div> : <div className="Chart-div">
        <Line
            data={state}
            options={{
                title: {
                    display: true, text: (symbol === undefined ? "" : symbol) + " Profit/Loss Chart", fontSize: 20
                }, legend: {
                    display: true, position: 'right'
                }, scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: true, labelString: 'Profit or Loss', fontSize: 10
                        }, ticks: {
                            callback: function (value, index, values) {
                                return '$' + value;
                            }
                        }
                    }], xAxes: [{
                        type: 'linear', scaleLabel: {
                            display: true, labelString: 'Price at Expiration', fontSize: 10
                        }, ticks: {
                            callback: function (value, index, values) {
                                return '$' + value;
                            }, min: Math.max(0, lower_bound), max: upper_bound,
                        },
                    }],
                }, plugins: {
                    zoom: {
                        // Container for pan options
                        pan: {
                            // Boolean to enable panning
                            enabled: true,

                            // Panning directions. Remove the appropriate direction to disable
                            // Eg. 'y' would only allow panning in the y direction
                            mode: 'xy'
                        },

                        // Container for zoom options
                        pinch: {
                            // Boolean to enable zooming
                            enabled: false,

                            // Zooming directions. Remove the appropriate direction to disable
                            // Eg. 'y' would only allow zooming in the y direction
                            mode: 'xy',
                        }, // Container for zoom options
                        wheel: {
                            // Boolean to enable zooming
                            enabled: false,

                            // Zooming directions. Remove the appropriate direction to disable
                            // Eg. 'y' would only allow zooming in the y direction
                            mode: 'xy',
                        }
                    }
                }, annotation: {
                    drawTime: 'beforeDatasetsDraw', annotations: verticalLines,
                },
            }}
        />
    </div>);
}

export default Chart;


/*  const [legTable, setLegTable] = useState({});

    useEffect(() => {
        setLegTable({
            "Custom (1 Leg)": ['+C'],
            "Custom (2 Legs)": ['+C', '+C'],
            "Custom (3 Legs)": ['+C', '+C', '+C'],
            "Custom (4 Legs)": ['+C', '+C', '+C', '+C'],
            "Bull Call Spread": ['+C', '-C'],
            "Bear Call Spread": ['+C', '-C'],
            "Bull Put Spread": ['+P', '-P'],
            "Bear Put Spread": ['+P', '-P'],
            "Long Straddle": ['+C', '+P'],
            "Long Strangle": ['+C', '+P'],
            "Long Call Butterfly": ['+C', '-C', '-C', '+C'],
            "Short Call Butterfly": ['-C', '+C', '+C', '-C'],
            "Long Put Butterfly": ['+P', '-P', '-P', '+P'],
            "Short Put Butterfly": ['-P', '+P', '+P', '-P'],
            "Iron Butterfly": ['+P', '-P', '-C', '+C'],
            "Reverse Iron Butterfly": ['-P', '+P', '+C', '-C'],
            "Iron Condor": ['+P', '-P', '-C', '+C'],
            "Jade Lizard": ['-P', '-C', '+C'],
            "Long Guts": ['+C', '+P']
        })
    }, []);*/