import * as constant from "./constants";
import axios from "axios";
import {dateFromObjectId} from "../services/Utils"

/*const requestDashboard = () => {
  return {
    type: constant.REQUEST_DASHBOARD,
  };
};*/
const receiveNewSignalAlert = () => {
    return {
        type: constant.RECEIVE_NEW_SIGNAL_ALERT,
    };
};
const receiveError = (error) => {
    return {
        type: constant.ERROR,
        error: error,
    };
};

const requestAllFollowerSignals = () => {
    return {
        type: constant.REQUEST_ALL_FOLLOWER_SIGNALS,
    };
};
const receiveAllFollowerSignals = (signals, allOpenTradeSignals, signalTableData, signalTableHeads, signalTableDataRows, totalSignals, signalsClosedInProfit, msg) => {
    return {
        type: constant.RECEIVE_ALL_FOLLOWER_SIGNALS,
        allSignalsForFollower: signals,
        signalTableData: signalTableData,
        signalTableHeads: signalTableHeads,
        totalSignals: totalSignals,
        signalsClosedInProfit: signalsClosedInProfit,
        allOpenTradeSignals: allOpenTradeSignals,
        getAllSignalsError: msg,
        signalTableDataRows: signalTableDataRows,
    };
};
const receiveGetAllSignalsError = (error) => {
    return {
        type: constant.RECEIVE_GET_ALL_SIGNALS_ERROR,
        getAllSignalsError: error,
    };
};

const receiveExecuteTradeSignalError = (error) => {
    return {
        type: constant.RECEIVE_EXECUTE_SIGNAL_ERROR,
        executeTradeSignalError: error,
    };
};
const receiveExecuteTradeSignal = (tradeConfirmation) => {
    return {
        type: constant.RECEIVE_EXECUTE_SIGNAL,
        tradeConfirmationId: tradeConfirmation.tradeConfirmationId,
    };
};
const requestExecuteTradeSignal = () => {
    return {
        type: constant.REQUEST_EXECUTE_SIGNAL,
    };
};

const receiveUpdateFollowerTradePrefs = (tradeConfirmation) => {
    return {
        type: constant.RECEIVE_FOLLOWER_SIGNAL_TRADE_PREFS,
        tradeConfirmationId: tradeConfirmation.tradeConfirmationId,
    };
};
const requestUpdateFollowerTradePrefs = () => {
    return {
        type: constant.REQUEST_FOLLOWER_SIGNAL_TRADE_PREFS,
    };
};

const receiveSignalTableDataRow = (signalTableDataLocal) => {
    return {
        type: constant.RECEIVE_SIGNAL_TABLE_DATAROW,
        signalTableData: signalTableDataLocal,
    };
};
const receiveTradeConfirmationDetails = (tradeConfirmationDetails) => {
    return {
        type: constant.RECEIVE_TRADE_CONFIRMATION_DETAILS,
        tradeConfirmationDetails: tradeConfirmationDetails,
    };
};
const receiveTradeConfirmationDetailsError = (error) => {
    return {
        type: constant.RECEIVE_TRADE_CONFIRMATION_DETAILS,
        executeTradeSignalError: error,
    };
};
const receiveInstrumentDetailsForBroker = (instrumentDetails) => {
    return {
        type: constant.RECEIVE_INSTRUMENT_DETAILS_FOR_BROKER,
        instrumentDetailsFromBroker: instrumentDetails,
    };
};
const requestInstrumentDetailsForBroker = () => {
    return {
        type: constant.REQUEST_INSTRUMENT_DETAILS_FOR_BROKER,
    };
};

const newSignalAlert = () => {
    //console.log("inside new signal alert triggered by websocket");
    return async (dispatch) => {
        try {
            dispatch(receiveNewSignalAlert());
        } catch (error) {
            const message = error.response.data;
            dispatch(receiveError(message));
        }
    };
};

const getAllSignalsForFollower = (followerEmail, followerId, updateDataRows, PROXY_ADDRESS, GETALLSIGNALSFORFOLLOWERURL) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const getFollowerSignalsRequest = {
        email: followerEmail,
        user_id: followerId
    };
    return async (dispatch) => {
        try {
            dispatch(requestAllFollowerSignals());
            let response = await axios.post(PROXY_ADDRESS + GETALLSIGNALSFORFOLLOWERURL, getFollowerSignalsRequest, {
                headers: headers
            })
            let signalTableHeads = await populateSignalTableHeaderFooterElements();
            let signalTableDataRows = [];
            let signalTableData = {
                columns: [],
                rows: []
            };
            signalTableData.columns = signalTableHeads;
            let allOpenTradeSignals = [];
            let signalsClosedInProfit = 0;
            const allOpenTradeSignalsFromAPI = response.data.allOpenSignals;
            const allSignalsCount = allOpenTradeSignalsFromAPI.length;
            if (allSignalsCount > 0) {
                [signalTableDataRows, allOpenTradeSignals, signalsClosedInProfit] = await populateSignalTableElements(allOpenTradeSignalsFromAPI);
                let signalsPercentClosedInProfit = (signalsClosedInProfit / (allSignalsCount - allOpenTradeSignals.length)) * 100;
                //let dataRows = await updateSignalTableDataFunction(signalTableData.dataRows, closeTradeFunction, getTradeConfirmation, executeTradeForSignal, ignoreSignalFunction, unIgnoreSignalFunction, followerId, handleOpen, handleClose, classes, open);
                signalTableData.rows = updateDataRows(signalTableDataRows);
                //console.log("this is the overall signaltabledata", signalTableData);
                dispatch(receiveAllFollowerSignals(allOpenTradeSignalsFromAPI, allOpenTradeSignals, signalTableData, signalTableHeads, signalTableDataRows, allSignalsCount, Math.round(signalsPercentClosedInProfit), ""));
            } else {
                [signalTableDataRows, allOpenTradeSignals, signalsClosedInProfit] = await populateSignalTableElements(allOpenTradeSignalsFromAPI);
                signalsClosedInProfit = 0;
                //signalTableData.dataRows = await updateSignalTableDataFunction(signalTableData.dataRows, closeTradeFunction, getTradeConfirmation, executeTradeForSignal, ignoreSignalFunction, unIgnoreSignalFunction, followerId, handleOpen, handleClose, classes, open);
                signalTableData.rows = updateDataRows(signalTableDataRows);
                let msg = "No Trade Signals found. Why not follow an expert to start receiving trade signals.";
                dispatch(receiveAllFollowerSignals(allOpenTradeSignalsFromAPI, allOpenTradeSignals, signalTableData, signalTableHeads, signalTableDataRows, allSignalsCount, Math.round(signalsClosedInProfit), msg));

            }
        } catch (error) {
            //const message = error.response.statusText;
            console.log("couldnt fetch signals for follower.", error)
            dispatch(receiveGetAllSignalsError(error));
        }
    }
};

const executeTradeSignal = (signalId, followerId, followerEmail, PROXY_ADDRESS, EXECUTETRADESIGNAL) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const executeSignalTradeRequest = {
        followerEmail: followerEmail,
        followerId: followerId,
        signalId: signalId,
    };
    return async (dispatch) => {
        try {
            dispatch(requestExecuteTradeSignal());
            let res = await axios.post(PROXY_ADDRESS + EXECUTETRADESIGNAL, executeSignalTradeRequest, {
                headers: headers
            })
            //console.log("this is the trade confirmation", res.data.tradeConfirmationId)
            dispatch(receiveExecuteTradeSignal(res.data));
        } catch (error) {
            const message = error.response.data;
            dispatch(receiveExecuteTradeSignalError(message));
        }
    };
};

const toggleOverallAutoTradingOptionAction = (email, overallAutoTradingEnabled, followerId, PROXY_ADDRESS, TOGGLEAUTOTRADEOPTION) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const toggleAutoTradeRequest = {
        email: email,
        autoTrade: overallAutoTradingEnabled,
        user_id: followerId,
    };
    return async (dispatch) => {
        try {
            dispatch(requestExecuteTradeSignal());
            let res = await axios.post(PROXY_ADDRESS + TOGGLEAUTOTRADEOPTION, toggleAutoTradeRequest, {
                headers: headers
            })
            console.log("this is the trade confirmation", res.data)
            dispatch(receiveExecuteTradeSignal(res.data));
        } catch (error) {
            const message = error.response.data;
            dispatch(receiveExecuteTradeSignalError(message));
        }
    };
};

const updateFollowerSignalPreferences = (signalId, followerId, email, tradePreferences, PROXY_ADDRESS, UPDATEFOLLOWERPREFERENCESTOSIGNAL) => {
    //console.log("follower preferences", signalId, email, tradePreferences);
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const followerSignalPreferencesRequest = {
        signalId: tradePreferences.signalId,
        //followerSuggestedSignalRisk: tradePreferences.followerSuggestedSignalRisk,
        followerSuggestedExecutionPrice: tradePreferences.followerSuggestedExecutionPrice,
        followerSuggestedExecutionQty: tradePreferences.followerSuggestedExecutionQty,
        followerSuggestedStopLoss: tradePreferences.followerSuggestedStopLoss,
        followerSuggestedLimit: tradePreferences.followerSuggestedLimit,
        spreadBet: tradePreferences.spreadBet,
        stocksShares: tradePreferences.stocksShares,
        options: tradePreferences.options,
        //followerSuggestedBrokerAccountId: tradePreferences.followerSuggestedBrokerAccountId,
        //followerSuggestedBrokerId: tradePreferences.followerSuggestedBrokerId,
        email: email,
    };
    return async (dispatch) => {
        try {
            dispatch(requestUpdateFollowerTradePrefs());
            let res = await axios.post(PROXY_ADDRESS + UPDATEFOLLOWERPREFERENCESTOSIGNAL, followerSignalPreferencesRequest, {
                headers: headers
            })
            console.log("this is the trade preferences response >> ", res.data)
            dispatch(receiveUpdateFollowerTradePrefs(res.data));
        } catch (error) {
            const message = error.response.data;
            dispatch(receiveExecuteTradeSignalError(message));
        }
    };
};

const closeTradeSignal = (signalId, followerId, followerEmail, dataRow, PROXY_ADDRESS, CLOSETRADESIGNAL) => {

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const executeSignalTradeRequest = {
        followerEmail: followerEmail,
        followerId: followerId,
        signalId: signalId,
    };
    return async (dispatch) => {
        try {
            dispatch(requestExecuteTradeSignal());
            let res = await axios.post(PROXY_ADDRESS + CLOSETRADESIGNAL, executeSignalTradeRequest, {
                headers: headers
            })
            //console.log("this is the trade confirmation", res.data.tradeConfirmationId)
            dispatch(receiveExecuteTradeSignal(res.data));
        } catch (error) {
            const message = error.response.data;
            dispatch(receiveExecuteTradeSignalError(message));
        }
    };
};

const getInstrumentDetailsForBroker = (signalId, instrumentId, followerEmail, PROXY_ADDRESS, GETINSTRUMENTDETAILSFORBROKER) => {
    //console.log("this is the instrument details from follower broker", signalId);
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const instrumentDetailsFromBrokerRequest = {
        followerEmail: followerEmail,
        instrumentId: instrumentId,
        signalId: signalId,
    };
    return async (dispatch) => {
        try {
            dispatch(requestInstrumentDetailsForBroker());
            let res = await axios.post(PROXY_ADDRESS + GETINSTRUMENTDETAILSFORBROKER, instrumentDetailsFromBrokerRequest, {
                headers: headers
            })
            //console.log("this is the instrument details from follower broker", res.data);
            dispatch(receiveInstrumentDetailsForBroker(res.data));
        } catch (error) {
            const message = error.response.data;
            dispatch(receiveExecuteTradeSignalError(message));
        }
    };
};

const getTradeConfirmationDetails = (tradeId, followerEmail, PROXY_ADDRESS, GETDEALCONFIRMATION) => {

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const executeSignalTradeRequest = {
        followerEmail: followerEmail,
        dealId: tradeId,
    };
    return async (dispatch) => {
        try {
            dispatch(requestExecuteTradeSignal());
            await axios.post(PROXY_ADDRESS + GETDEALCONFIRMATION, executeSignalTradeRequest, {
                headers: headers
            }).then(res => {
                //console.log("this is the trade confirmation", res.data.positions.length);
                if (res.data.positions && res.data.positions.length > 0) {
                    //console.log("t222");
                    res.data.positions.map((item, index) => {
                        if (index === 0) {
                            //console.log("this is the trade confirmation", item);
                            dispatch(receiveTradeConfirmationDetails(item));
                        }
                    })
                } else {
                    dispatch(receiveTradeConfirmationDetailsError("no trade confirmation found"));
                }
            })
        } catch (error) {
            const message = error.response.data;
            dispatch(receiveTradeConfirmationDetailsError(message));
        }
    };
};

const localUpdateSignalDataRow = (updateDataRows, signalTableDataRows) => {
    return async (dispatch) => {
        try {
            //signalTableData.rows = [];
            let signalTableHeads = await populateSignalTableHeaderFooterElements();
            let signalTableDataLocal = {
                columns: [],
                rows: []
            };
            signalTableDataLocal.columns = signalTableHeads;
            let dataRows = updateDataRows(signalTableDataRows);
            signalTableDataLocal.rows = dataRows;
            dispatch(receiveSignalTableDataRow(signalTableDataLocal));
        } catch (error) {
            //const message = error.response.statusText;
            console.log("couldnt fetch signals for follower.", error)
            dispatch(receiveGetAllSignalsError(error));
        }

    }
}

async function populateSignalTableElements(allTradeSignalsFromAPI) {
    try {
        let dataRows = [];
        let allOpenTradeSignals = [];
        let signalsClosedInProfit = 0;
        await allTradeSignalsFromAPI.map(signal => {
            if (!signal.tradeStillOpen) {
                if (signal.signalPerformance > 0) {
                    ++signalsClosedInProfit
                } else {
                    let tradeSignal = [];
                    let date = dateFromObjectId(signal.signalId);
                    tradeSignal.push(date.toString());
                    tradeSignal.push(signal.signalInstrumentId);
                    tradeSignal.push(signal.signalSuggestedTradeDirection);
                    tradeSignal.push(signal.spotPriceWhenSignalInitiated);
                    tradeSignal.push(signal.spotPriceWhenSignalClosed);
                    tradeSignal.push(signal.signalId);
                    //console.log("ZERO", tradeSignal);
                    allOpenTradeSignals.push(tradeSignal);
                }
            }
            let tradeSignal = [];
            let date = dateFromObjectId(signal.signalId);
            tradeSignal.push(date.toString());                      //0
            tradeSignal.push(signal.signalInstrumentId);            //1
            tradeSignal.push(signal.signalSuggestedTradeDirection); //2
            tradeSignal.push(signal.expertName);                    //3
            tradeSignal.push(signal.expertEmail);                   //4
            tradeSignal.push(signal.signalId);                      //5
            tradeSignal.push(signal.expertId);                      //6
            tradeSignal.push(signal.tradeStillOpen);                //7
            tradeSignal.push(signal.signalTraded);                  //8
            tradeSignal.push(signal.signalIgnoredByFollower);       //9
            tradeSignal.push(signal.tradeId);                       //10
            tradeSignal.push(signal.signalStillOpen);               //11
            tradeSignal.push(signal.trade.tradePriceLevelWhenOpen);          //12
            tradeSignal.push(signal.trade.tradePriceLevelWhenClosed);        //13
            tradeSignal.push(signal.trade.tradeConfirmationStatus);          //14
            tradeSignal.push(signal.trade.profitOfTradeWhenClosed);          //15
            tradeSignal.push(signal.trade.sizeOfTradeWhenOpen);              //16
            tradeSignal.push(signal.signalSuggestedExecutionPrice);          //17
            tradeSignal.push(signal.signalSuggestedLimitPrice);              //18
            tradeSignal.push(signal.signalSuggestedStopLossPrice);           //19
            tradeSignal.push(0);           //20
            dataRows.push(tradeSignal);
        });
        return [dataRows, allOpenTradeSignals, signalsClosedInProfit];
    } catch (error) {
        const message = error.response.data;
        console.log("error while create signal table elements", message);
    }
}

async function populateSignalTableHeaderFooterElements() {
    let signalTableHeads = [
        {
            Header: "Origin Date",
            accessor: "originDate",
            width: "5%"
        },
        {
            Header: "Expert Name",
            accessor: "expertName",
            width: "8%"
        },
        {
            Header: "Instrument ID",
            accessor: "signalInstrumentId",
            width: "5%"
        },
        {
            Header: "Trade Direction",
            accessor: "signalSuggestedTradeDirection",
            width: "5%"
        },
        {
            Header: "Signal Status",
            accessor: "signalStatus",
            width: "5%"
        },
        {
            Header: "Suggested Execution Price",
            accessor: "signalSuggestedExecutionPrice",
            width: "5%"
        },
        {
            Header: "Trade Execution Price",
            accessor: "tradePriceLevelWhenOpen",
            width: "5%"
        },
        {
            Header: "Suggested Closing Price",
            accessor: "signalSuggestedLimitPrice",
            width: "5%"
        },
        {
            Header: "Trade Closing Price",
            accessor: "tradePriceLevelWhenClosed",
            width: "5%"
        },
        {
            Header: "Size Of Trade",
            accessor: "sizeOfTradeWhenOpen",
            width: "5%"
        },
        {
            Header: "Trade P&L",
            accessor: "profitOfTradeWhenClosed",
            width: "7%"
        },
        {
            Header: "Actions",
            accessor: "actions",
            width: "5%"
        },
    ];
    return signalTableHeads;
}

/*async function updateSignalTableDataFunction (dataRows, closeTradeFunction, getTradeConfirmation, executeTradeForSignal, ignoreSignalFunction, unIgnoreSignalFunction, userId, handleOpen, handleClose, classes, open) {
    let data = [];
    dataRows.map((prop, key) => {
            let dataRow = {
                id: key,
                originDate: prop[0],
                signalInstrumentId: prop[1],
                signalSuggestedTradeDirection: prop[2],
                signalStatus: prop[11] === true ? "Active" : "Inactive",
                expertName: prop[3],
                signalId: prop[5],
                expertId: prop[6],
                tradeStillOpen: prop[7],
                signalTraded: prop[8],
                signalIgnoredByFollower: prop[9] === true ? "Ignored" : "Not Ignored",
                tradeId: prop[10]===""?"Signal Trade Not Executed" : prop[10],
                signalStillOpen: prop[11],
                tradePriceLevelWhenOpen: prop[12]/100,
                tradePriceLevelWhenClosed: prop[13]/100,
                tradeConfirmationStatus: prop[14],
                profitOfTradeWhenClosed: prop[15],
                sizeOfTradeWhenOpen: prop[16],
                signalSuggestedExecutionPrice: prop[17],
                signalSuggestedLimitPrice: prop[18],
                signalSuggestedStopLossPrice: prop[19],
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right" >
                        {/!* use this button to add a like kind of action *!/}
                        {prop[11] && !prop[9] && !prop[8] ?
                            <Tooltip
                                id="tooltip-top"
                                title="Trade this Signal"
                                placement="bottom"
                                //classes={{ tooltip: classes.tooltip }}
                            >
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        /!*let obj = data.find((o) => o.id === key);
                                        console.log(obj)*!/
                                        executeTradeForSignal(dataRow.signalId, userId, dataRow);
                                    }}
                                    color="info"
                                    className="like"
                                >
                                    <Open />
                                </Button>
                            </Tooltip>:""}
                        {/!* use this button to add a edit kind of action *!/}
                        {prop[8] ?
                            <>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Get Trade Confirmation"
                                    placement="bottom"
                                    //classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => handleOpen()}
                                        color="warning"
                                        className="edit"
                                    >
                                        <TradeConfirmation/>
                                    </Button>
                                </Tooltip>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={open}
                                    onClose={handleClose()}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={open}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">Transition modal</h2>
                                            <p id="transition-modal-description">react-transition-group animates me.</p>
                                        </div>
                                    </Fade>
                                </Modal>
                            </>
                            /!*<Tooltip
                                id="tooltip-top"
                                title="Get Trade Confirmation"
                                placement="bottom"
                                //classes={{ tooltip: classes.tooltip }}
                            >
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        /!* let obj = data.find((o) => o.id === key);
                                         console.log(obj)*!/
                                        getTradeConfirmation(dataRow.signalId, userId, dataRow);
                                    }}
                                    color="warning"
                                    size="lg"
                                    className="edit"
                                >
                                    <TradeConfirmation />
                                </Button>
                            </Tooltip>*!/
                            :
                            ""}
                        {/!* use this button to remove the data row *!/}
                        {prop[7] ?
                            <Tooltip
                                id="tooltip-top"
                                title="Close this Trade"
                                placement="bottom"
                                //classes={{ tooltip: classes.tooltip }}
                            >
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        /!*data.find((o) => {
                                            if (o.id === key) {
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                console.log(o.signalInstrumentId, userId, o.signalInstrumentId);*!/
                                        //console.log("this is the number array in which we found signal to close", i);
                                        closeTradeFunction(dataRow.signalId, userId, dataRow.signalInstrumentId);
                                        /!*                                    //o.tradeStillOpen = false;
                                                                            //delete the current o and replace it with new
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    });*!/
                                    }}
                                    color="danger"
                                    /!*size="sm"*!/
                                    className="remove"
                                >
                                    <Close/>
                                </Button>
                            </Tooltip>:""}
                        {/!* use this button to remove the data row *!/}
                        {prop[9] ?
                            <Tooltip
                                id="tooltip-top"
                                title="Like this Signal"
                                placement="bottom"
                                //classes={{ tooltip: classes.tooltip }}
                            >
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        /!*data.find((o) => {
                                            if (o.id === key) {
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                console.log(o.signalInstrumentId, userId, o.signalInstrumentId);*!/
                                        //console.log("this is the number array in which we found signal to close", i);
                                        unIgnoreSignalFunction(dataRow.signalId, userId);
                                        /!*                                    //o.tradeStillOpen = false;
                                                                            //delete the current o and replace it with new
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    });*!/
                                    }}
                                    color="danger"
                                    /!*size="sm"*!/
                                    className="remove"
                                >
                                    <Ignore/>
                                </Button>
                            </Tooltip>
                            : <Tooltip
                                    id="tooltip-top"
                                    title="Ignore this Signal"
                                    placement="bottom"
                                    //classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                            /!*data.find((o) => {
                                                if (o.id === key) {
                                                    // here you should add some custom code so you can delete the data
                                                    // from this component and from your server as well
                                                    console.log(o.signalInstrumentId, userId, o.signalInstrumentId);*!/
                                            //console.log("this is the number array in which we found signal to close", i);
                                            ignoreSignalFunction(dataRow.signalId, userId);
                                            /!*                                    //o.tradeStillOpen = false;
                                                                                //delete the current o and replace it with new
                                                                                return true;
                                                                            }
                                                                            return false;
                                                                        });*!/
                                        }}
                                        color="danger"
                                        /!*size="sm"*!/
                                        className="remove"
                                    >
                                        <IgnoreUnfilled/>
                                    </Button>
                                </Tooltip> }
                    </div>
                )
            }
            data.push(dataRow)
    });
    return data;
}*/

export {
    getAllSignalsForFollower,
    updateFollowerSignalPreferences,
    executeTradeSignal,
    closeTradeSignal,
    toggleOverallAutoTradingOptionAction,
    getTradeConfirmationDetails,
    localUpdateSignalDataRow,
    newSignalAlert,
    getInstrumentDetailsForBroker
};
