import * as constant from "./constants";
import testData from "./test-data";
import axios from "axios";

//require('dotenv').config();

const requestNotifications = () => {
  return {
    type: constant.REQUEST_NOTIFICATIONS,
  };
};
const receiveNotifications = (notifications) => {
  return {
    type: constant.RECEIVE_NOTIFICATIONS,
    notifications: notifications,
  };
};
const receiveStockOptionsExpiryDate = (expiryDates) => {
  return {
    type: constant.RECEIVE_STOCK_OPTIONS_EXPIRY_DATE,
    expiryDates: expiryDates
  };
};
const receiveTickerInfo = (tickerInfo) => {
  return {
    type: constant.RECEIVE_TICKER_INFO,
    tickerInfo: tickerInfo
  };
};
const receiveStockOptionsPCRAndMaxPain = (pcr, maxPain, bufferLow, bufferHigh, tickerInfo) => {
  return {
    type: constant.RECEIVE_STOCK_OPTIONS_PCR_MAXPAIN,
    putCallRatio: pcr,
    maxPain: maxPain,
    bufferHigh: bufferHigh,
    bufferLow: bufferLow
  };
};const receiveOptionsStrategies = (optionStrategies) => {
  return {
    type: constant.RECEIVE_STOCK_OPTIONS_STRATEGIES,
    optionStrategies: optionStrategies,
  };
};
const receiveError = (error) => {
  return {
    type: constant.ERROR,
    error: error,
  };
};
const requestAllExpertSignals = () => {
  return {
    type: constant.REQUEST_ALL_EXPERT_SIGNALS,
  };
};

const requestCloseExistingSignal = () => {
  return {
    type: constant.REQUEST_CLOSE_EXISTING_SIGNAL,
  };
};
const receiveCloseExistingSignal = () => {
  return {
    type: constant.RECEIVE_CLOSE_EXISTING_SIGNAL,
  };
};
const receiveCloseSignalError = (error) => {
  return {
    type: constant.RECEIVE_CLOSE_EXISTING_SIGNAL_ERROR,
    closeSignalError: error.toString(),
  };
};
const requestOptionStrategies = () => {
  return {
    type: constant.REQUEST_STOCK_OPTIONS_STRATEGIES,
  };
};

const getNotifications = () => {
  return async (dispatch) => {
    try {
      dispatch(requestNotifications());
      // const res = await axios.get(SERVICE_API + "/products");
      const res = {
        data: testData,
      };
      dispatch(receiveNotifications(res.data));
    } catch (error) {
      const message = error.response.data;
      dispatch(receiveError(message));
    }
  };
};

const getAllSignalsForFollower = (followerEmail, followerId, PROXY_ADDRESS, GETALLSIGNALSFORFOLLOWERURL) => {

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const getFollowerSignalsRequest = {
    email: followerEmail,
    user_id: followerId
  };
  return async (dispatch) => {
    try {
      dispatch(requestAllExpertSignals());
      let res = await axios.post(PROXY_ADDRESS + GETALLSIGNALSFORFOLLOWERURL,  getFollowerSignalsRequest, {
        headers: headers
      })
          .then((response) => {
            //console.log("RESPONSE RECEIVED for all follower signals: >>> ", response);
            dispatch(receiveNotifications(response.data))
          }).catch((error) => {
            console.log("AXIOS ERROR: ", error.toString());
            console.log("AXIOS ERROR: response status text", res.statusText);
            dispatch(receiveError(res.statusText))
          })
      /*const res = {
        data: {
          name: "Niku Sharma",
          role: "admin",
          menu: ["test", "test"],
        },
      };*/
    } catch (error) {
      const message = error.response.data;
      dispatch(receiveError(message));
    }
  }
};

const closeSignal = (signalId, expertId, instrumentId, expertEmail, PROXY_ADDRESS, CLOSEEXISTINGTRADESIGNAL) => {

  //console.log("inside close signal action", signalId, expertId, instrumentId, expertEmail);
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const closeExistingExpertSignalRequest = {
    expertId: expertId,
    signalId: signalId,
    instrumentId: instrumentId,
    expertEmail: expertEmail,
  };
  return async (dispatch) => {
    try {
      dispatch(requestCloseExistingSignal());
      let res = await axios.post(PROXY_ADDRESS + CLOSEEXISTINGTRADESIGNAL,  closeExistingExpertSignalRequest, {
        headers: headers
      })
          .then(() => {
            //console.log("RESPONSE RECEIVED for all expert signals: >>> ", response);
            dispatch(receiveCloseExistingSignal());
          }).catch((error) => {
            console.log("AXIOS ERROR: ", error.toString());
            console.log("AXIOS ERROR: response status text", res.statusText);
            dispatch(receiveError(res.statusText))
          })
      /*const res = {
        data: {
          name: "Niku Sharma",
          role: "admin",
          menu: ["test", "test"],
        },
      };*/
    } catch (error) {
      //const message = error.response.data;
      dispatch(receiveCloseSignalError(error));
    }
  }
};
const ignoreSignal = (signalId, followerId, followerEmail, PROXY_ADDRESS, IGNORESIGNALFORFOLLOWER) => {

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const ignoreSignalRequest = {
    followerId: followerId,
    signalId: signalId,
    email: followerEmail,
  };
  return async (dispatch) => {
    try {
      dispatch(requestNotifications());
      await axios.post(PROXY_ADDRESS + IGNORESIGNALFORFOLLOWER,  ignoreSignalRequest, {
        headers: headers
      })
          .then(() => {
            //console.log("RESPONSE RECEIVED for all expert signals: >>> ", response);
            dispatch(receiveCloseExistingSignal());
          }).catch((error) => {
            console.log("AXIOS ERROR: ", error.toString());
            dispatch(receiveError(error.toString()))
          })
    } catch (error) {
      //const message = error.response.data;
      dispatch(receiveError(error));
    }
  }
};
const unIgnoreSignal = (signalId, followerId, followerEmail, PROXY_ADDRESS, UNIGNORESIGNALFORFOLLOWER) => {

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const unIgnoreSignalRequest = {
    followerId: followerId,
    signalId: signalId,
    email: followerEmail,
  };
  return async (dispatch) => {
    try {
      dispatch(requestNotifications());
      await axios.post(PROXY_ADDRESS + UNIGNORESIGNALFORFOLLOWER,  unIgnoreSignalRequest, {
        headers: headers
      })
          .then(() => {
            //console.log("RESPONSE RECEIVED for all expert signals: >>> ", response);
            dispatch(receiveCloseExistingSignal());
          }).catch((error) => {
            console.log("AXIOS ERROR: ", error.toString());
            dispatch(receiveError(error.toString()))
          })
    } catch (error) {
      //const message = error.response.data;
      dispatch(receiveError(error));
    }
  }
};

// get all getStockOptionsExpiryDates
const getStockOptionsExpiryDates = (signalId, followerEmail, instrumentId, PROXY_ADDRESS, GETSTOCKOPTIONSEXPIRYDATES) => {

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const getStockOpExpiryDatesReq = {
    symbol: instrumentId,
    stockName: "",
  };
  return async (dispatch) => {
    try {
      dispatch(requestNotifications());
      await axios.post(PROXY_ADDRESS + GETSTOCKOPTIONSEXPIRYDATES,  getStockOpExpiryDatesReq, {
        headers: headers
      })
          .then((res) => {
            if (res.data.expiryDates && res.data.expiryDates.length > 0) {
              //console.log("expiryDates", res.data);
              dispatch(receiveStockOptionsExpiryDate(res.data.expiryDates));
            }else{
              dispatch(receiveStockOptionsExpiryDate([]));
            }
          })
    } catch (error) {
      const message = error.response.data;
      dispatch(receiveError(message));
    }
  }
}

// get all signals that follower has received from all the experts they are following
const getStockPCRAndMaxPain = (signalId, expiryDate, instrumentId, PROXY_ADDRESS, GETSTOCKPCRANDMAXPAIN) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const getStockOpPCRAndMaxPainReq = {
    symbol: instrumentId,
    stockName: "",
    expiry: expiryDate,
  };
  return async (dispatch) => {
    try {
      dispatch(requestNotifications());
      await axios.post(PROXY_ADDRESS + GETSTOCKPCRANDMAXPAIN,  getStockOpPCRAndMaxPainReq, {
        headers: headers
      })
          .then((res) => {
            //console.log("pcr and max pain ==== ", res.data);
            dispatch(receiveStockOptionsPCRAndMaxPain(res.data.putCallRatio, res.data.maxPain, res.data.bufferLow, res.data.bufferHigh));
          })
    } catch (error) {
      const message = error.response.data;
      dispatch(receiveError(message));
    }
  }
}

// get all getStockOptionsExpiryDates
const getTickerInfo = (followerEmail, instrumentId, PROXY_ADDRESS, GETTICKERINFO) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const getStockOpExpiryDatesReq = {
    symbol: instrumentId,
    stockName: "",
  };
  return async (dispatch) => {
    try {
      dispatch(requestNotifications());
      await axios.post(PROXY_ADDRESS + GETTICKERINFO,  getStockOpExpiryDatesReq, {
        headers: headers
      })
          .then((res) => {
            //console.log("expiryDates", res.data);
            if (res.data) {
              //console.log("expiryDates", res.data);
              dispatch(receiveTickerInfo(res.data));
            }else{
              dispatch(receiveTickerInfo([]));
            }
          })
    } catch (error) {
      const message = error.data;
      dispatch(receiveError(message));
    }
  }
}

// get all signals that follower has received from all the experts they are following
const getOptionsStrategies = (signalId, userEmail, signalSuggestedTradeDirection, instrumentId, PROXY_ADDRESS, GETIRONCONDOROPTIONSTRADEVALUES) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const optionsTradeValuesReq = {
    symbol: instrumentId,
    stockName: "",
    direction: signalSuggestedTradeDirection,
  };
  return async (dispatch) => {
    try {
      dispatch(requestOptionStrategies());
      await axios.post(PROXY_ADDRESS + GETIRONCONDOROPTIONSTRADEVALUES,  optionsTradeValuesReq, {
        headers: headers
      })
          .then((res) => {
            console.log("option strategy ==== ", res.data);
            if (res.data !== undefined) {
                    dispatch(receiveOptionsStrategies(res.data));
            }
          });
    } catch (error) {
      const message = error;
      dispatch(receiveError(message));
    }
  }
}

/*const updateChartDataFromStrategies = (optionStrategies) => {
  const borderColors = ["#191716", "#E6AF2E", "#E0E2DB", "#BEB7A4"];
  let chart_data = [];
  let strikes = [];
  let high = 290;
  let low = 220;
  let quote = optionStrategies.spotPrice;
  let lower_bound = Math.ceil(low - quote/10 - 10);
  let upper_bound = Math.ceil(high + quote/10 + 10);
    for (let j = 0; optionStrategies.legs !== undefined && j < Object.keys(optionStrategies.legs).length; j++){
      if (Number(optionStrategies.legs[j].strike) + quote/10 > upper_bound){
        upper_bound = Number(optionStrategies.legs[j].strike) + quote/10;
      }
      strikes.push(optionStrategies.legs[j].strike);
    }
    chart_data.push([]);
    if (low + high + quote !== 0){
      let cur_x = lower_bound;
      while (cur_x <= upper_bound){
        chart_data[0].push({x: Number(cur_x).toFixed(2), y: 0});
        strikes.forEach((strike) => {
          if (strike > cur_x && strike < cur_x + 10){
            chart_data[0].push({x: strike, y: 0 });
          }
        });
        cur_x += 10;
      }
    }
    //console.log("this is chart_data", chart_data, strikes);
  for (let j = 0; optionStrategies.legs !== undefined && j < Object.keys(optionStrategies.legs).length; j++) {
    let factor = optionStrategies.legs[j].direction === process.env.REACT_APP_TRADE_DIRECTION_SHORT ? 1 : -1
    let type = optionStrategies.legs[j].type;
    let premium = optionStrategies.legs[j].premium * 100;
    let strike = optionStrategies.legs[j].strike;
    let quantity = optionStrategies.legs[j].quantity;
    for (let k = 0; k < chart_data[0].length; k++) {
      let x = parseFloat(chart_data[0][k].x);
      let cur_y = factor * premium;

      // Handle calls
      if (type === process.env.REACT_APP_OPTION_TYPE_CALL && x >= strike) {
        cur_y = -factor * ((x - strike) * 100 - premium);
      }

      // Handle puts
      if (type === process.env.REACT_APP_OPTION_TYPE_PUT && x <= strike) {
        cur_y = -factor * ((strike - x) * 100 - premium)
      }
      if (premium !== 0 && strike !== 0 && premium !== undefined && strike !== undefined) {
        chart_data[0][k].y += cur_y * quantity;
      }
    }
  }
  //console.log("strikes and chart data",strikes, chart_data);
  let dataSetsState = {datasets: []};
  dataSetsState.datasets.push({
    label: "Strategy " + (optionStrategies.strategyName),
    fill: false,
    lineTension: 0,
    backgroundColor: 'rgba(75, 192, 192, 1)',
    borderColor: borderColors[0],
    borderWidth: 2,
    data: chart_data,
  });
  /!*let dataLabel = "";
  let verticalLines = [];
  if (high !== 0){
    const intQuote = Math.floor(quote / 5) * 5;
    let third = (quote - intQuote) * 100 - 150;
    if (quote < intQuote) {
      third = -150;
    }
    dataLabel = "Long " + intQuote + " call @ 1.50";
    verticalLines = [
      {
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: Number(low).toFixed(2),
        borderColor: "#38677D",
        borderWidth: 2,
        borderDash: [2],

        label: {
          backgroundColor: "#959296",
          content: "52 wk Low",
          position: "bottom",
          enabled: true
        }
      },
      {
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: Number(quote).toFixed(2),
        borderColor: "#38677D",
        borderWidth: 2,
        borderDash: [2],

        label: {
          backgroundColor: "#959296",
          content: "Current Price",
          enabled: true
        }
      } ,
      {
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: Number(high).toFixed(2),
        borderColor: "#38677D",
        borderWidth: 2,
        borderDash: [2],

        label: {
          backgroundColor: "#959296",
          content: "52 wk High",
          position: "top",
          enabled: true
        }
      }
    ];
  }*!/
  return chart_data, dataSetsState;
}*/

export {getNotifications, getAllSignalsForFollower, closeSignal, ignoreSignal, unIgnoreSignal, getStockPCRAndMaxPain, getStockOptionsExpiryDates, getTickerInfo, getOptionsStrategies};