import * as constant from "./constants";

const initialState = {
  isLoading: false,
  fetchingAllSignals: false,
  error: null,
  getAllSignalsError: null,
  dashboard: {
    name: null,
    signals: {
      signalsPerformance: {
        isLoading: false,
        LastWeek: [],
        LastMonth: [],
        LastYear: [],
        lastUpdated: ""
      },
      signalsGenerated: {
        isLoading: false,
        LastWeek: [],
        LastMonth: [],
        LastYear: [],
        lastSignalSentDateTime: ""
      },
      followersExecutedTradeSignals: {
        isLoading: false,
        LastWeek: [],
        LastMonth: [],
        LastYear: [],
        lastUpdated: ""
      },
      totalSignals: 0,
      signalsClosedInProfit: 0,
      dollarOneInvestedNowIs:0,
      newSignal :{ }
    },
  },
  followers: 0,
  signalTableHeads: [],
  signalTableData: {
    columns: [],
    rows:[]
  },
  allOpenTradeSignals: [],
  allSignalsForExpert: []
};
const expertDashboardState = (state = initialState, action) => {
  switch (action.type) {

    case constant.ERROR: {
      return { ...state, isLoading: false, error: action.error };
    }

    case constant.REQUEST_ALL_EXPERT_SIGNALS: {
      return {
        ...state,
        fetchingAllSignals: true,
      };
    }

    case constant.RECEIVE_ALL_EXPERT_SIGNALS: {
      return {
        ...state,
        fetchingAllSignals: false,
        allSignalsForExpert: action.allSignalsForExpert,
        allOpenTradeSignals: action.allOpenTradeSignals,
        signalTableData: action.signalTableData,
        signalTableHeads: action.signalTableHeads,
        dashboard: {
          signals : {
            totalSignals: action.totalSignals,
            signalsClosedInProfit: action.signalsClosedInProfit,
            dollarOneInvestedNowIs:1.3,
          }
        },
        getAllSignalsError: action.getAllSignalsError,
      };
    }
    case constant.RECEIVE_GET_ALL_SIGNALS_ERROR: {
      return {
        ...state,
        fetchingAllSignals: false,
        getAllSignalsError: action.getAllSignalsError,
      };
    }
    case constant.RECEIVE_SIGNALS_TABLE_DATA: {
      return {
        ...state,
        signalTableData: action.signalTableData,
      };
    }
    default:
      return state;
  }
};
export default expertDashboardState;
