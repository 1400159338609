import * as constant from "./constants";
//const token = localStorage.getItem('token');
const initialState = {
  isLoading: true,
  error: null,
  user: null,
  isAuthenticated: false,
  isFetching: true,
  refreshingUser: false,
};
const loginState = (state = initialState, action) => {
  switch (action.type) {
    case constant.LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isAuthenticated: false
      };
    }

    case constant.LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isAuthenticated: false,
        error: action.error
      };
    }

    case constant.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isAuthenticated: true,
        error: '',
        user: action.user,
      };
    }

    case constant.FAILURE: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        refreshingUser: false,
        error: action.error
      };
    }

    case constant.REFRESH_USER_REQUEST: {
      return {
        ...state,
        refreshingUser: true,
      };
    }

    case constant.REFRESH_USER_SUCCESS: {
      return {
        ...state,
        refreshingUser: false,
        error: '',
        user: action.user,
      };
    }

    case constant.LOGOUT_REQUEST: {
        return {
          ...state,
          isAuthenticated: false,
          isFetching: true,
          user: null,
        };
      }
    case constant.LOGOUT_SUCCESS: {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isLoading: null,
      };
    }
    default:
      return state;
  }
};
export default loginState;
