/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux'
import App from "./App";
import {store, persistor} from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "./context";

createRoot(document.getElementById("root"))
   .render(
       /*<StrictMode>*/
       <CookiesProvider>
           <Provider store={store}>
              <BrowserRouter>
                <MaterialUIControllerProvider>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </MaterialUIControllerProvider>
              </BrowserRouter>
           </Provider>
       </CookiesProvider>
       /*</StrictMode>*/
    );
