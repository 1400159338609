import * as constant from "./constants";
import axios from "axios";

const requestLogin = () => {
  return {
    type: constant.LOGIN_REQUEST,
  };
};
const receiveLogin = (user) => {
  return {
    type: constant.LOGIN_SUCCESS,
    user: user,
  };
};

const loginError = (error) => {
  return {
    type: constant.LOGIN_FAILURE,
    error: error,
  };
};

const requestRefreshUser = () => {
  return {
    type: constant.REFRESH_USER_REQUEST,
  };
};
const receiveRefreshedUser = (user) => {
  return {
    type: constant.REFRESH_USER_SUCCESS,
    user: user,
  };
};

const receiveError = (error) => {
  return {
    type: constant.FAILURE,
    error: error,
  };
};

const requestLogout = ()  => {
  return {
    type: constant.LOGOUT_REQUEST,
    user: null,
  };
};

const receiveLogout = () => {
  return {
    type: constant.LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    user: null,
  };
};
//const [cookies] = useCookies(['user']);
//const PROXY_ADDRESS  = localStorage.getItem("PROXY_ADDRESS")
//console.log("this is the config process --- ", process.env)

//console.log(PROXY_ADDRESS);

const callLogin = (email, password, PROXY_ADDRESS, LOGINURL) => {
/*  console.log("this is the email and password --- ", email)*/
  //const [cookies] = useCookies(['user']);
  //const PROXY_ADDRESS  = localStorage.getItem("PROXY_ADDRESS")
  //Manually adding address because the first time website loads, it doesnt load cookies.

  const headers = {
    'Content-Type': 'application/json',
  }
  const loginRequest = {
    email: email,
    password: password
  };
  return async (dispatch) => {
    try {
      dispatch(requestLogin());
      //console.log("proxy address i action login --- ", cookies);
      //console.log("proxy address --- ", PROXY_ADDRESS);
      await axios.post(PROXY_ADDRESS + LOGINURL,  loginRequest, {
        headers: headers
      })
          .then((response) => {
            localStorage.setItem('id_token', response.data.token);
            localStorage.setItem('id', response.data.id);
            //console.log("RESPONSE RECEIVED in LOGIN ACTION: >>> ", response);
            dispatch(receiveLogin(response.data))
          }).catch((error) => {
            console.log("AXIOS ERROR: ", error.toString());
            dispatch(loginError(error))
          })
    } catch (error) {
      //const message = error.response.data;
      dispatch(receiveError(error.toString()));
    }
  };
};

// Logs the user out
const logoutUser = () => {
  return async dispatch => {
    function onSuccess(success) {
      localStorage.removeItem('id_token');
      localStorage.removeItem('id');
      //localStorage.clear();
      document.cookie = 'id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      return success;
    }
    try {
      let success = await dispatch(requestLogout());
      success = await onSuccess(success);
      //console.log("logout success -- ", success);
      dispatch(receiveLogout(success));
    } catch (error) {
      //const message = error.response.data;
      dispatch(receiveError(error.toString()));
    }
  };
};

// Logs the user out
const refreshUserData = (email, PROXY_ADDRESS, GETUSER) => {
  //Manually adding address because the first time website loads, it doesnt load cookies.
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const userRequest = {
    email: email,
  };
  return async (dispatch) => {
    try {
      dispatch(requestRefreshUser());
      //console.log("proxy address i action login --- ", cookies);
      //console.log("proxy address --- ", PROXY_ADDRESS);
      await axios.post(PROXY_ADDRESS + GETUSER,  userRequest, {
        headers: headers
      })
          .then((response) => {
            /*console.log("RESPONSE RECEIVED in LOGIN ACTION: >>> ", response);*/
            dispatch(receiveRefreshedUser(response.data))
          }).catch((error) => {
            console.log("AXIOS ERROR: ", error.toString());
            dispatch(receiveError(error))
          })
    } catch (error) {
      //const message = error.response.data;
      dispatch(receiveError(error.toString()));
    }
  };
};


export {callLogin, logoutUser, refreshUserData};