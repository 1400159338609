//require('dotenv').config();
import {useEffect, useState} from "react";
import {callLogin} from "../../middleware/login/actions";
import { useDispatch, useSelector } from "react-redux";
import { history } from '_helpers/history';
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "yolo/components/AuthLayout/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

/*import jwtDecode from "jwt-decode";*/
/*function isAuthenticationValid(token) {
  // We check if app runs with backend mode
  //if (!config.isBackend && token) return true;
  console.log("inside is auth valid", token);
  if (!token) return;
  console.log("token is valid");
  //const date = new Date().getTime() / 1000;
  const data = jwtDecode(token);
  console.log("data inside jwt token", data);
  /!*return date < data.exp;*!/
  return !(Date.now() >= data * 1000);
}*/
const LoginPage = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });

  const { email, password } = inputs;
 // const loggingIn = useSelector(state => state.loginState.isLoading);

  const dispatch = useDispatch();

  let userDetails = useSelector((state) => ({
    isLoading: state.loginState.isLoading,
    isFetching: state.loginState.isFetching,
    isAuthenticated: state.loginState.isAuthenticated,
    error: state.loginState.error,
    user: state.loginState.user,
  }));
  const { user, isAuthenticated } = userDetails;

  useEffect(() => {
    if (isAuthenticated && user){
      //console.log("user is already authenticated. send to dashboard");
      /* if (isAuthenticationValid(localStorage.getItem("id_token"))) {*/
      /*if (true) {*/
      //console.log("auth is valid so going to admin page");
      if (user && user.type === 1) {
        //console.log("inside checks", isAuthenticated, user);
        //history("/admin/expert-dashboard");
        history.navigate("/dashboards/expert-dashboard");
      } else if (user && user.type === 2) {
        //history("/admin/follower-dashboard");
        history.navigate("/dashboards/follower-dashboard");
      }
      /* }else{
         console.log("Is authenticated but the jwt token isnt valid or expired, going to login page");
       }*/
    }
  });

  function handleChange(e) {
    const { id, value } = e.target;
    setInputs(inputs => ({ ...inputs, [id]: value }));
  }
  const login = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      userDetails.error = "Fields are required";
      return;
    }
    //console.log("proxy address from VAULT environment ", process.env.REACT_APP_NODE_ENV + process.env.REACT_APP_PROXY_ADDRESS);
    //console.log("proxy address from VAULT environment API_KEY ===> ", process.env.REACT_APP_API_KEY);
    dispatch(callLogin(email, password, process.env.REACT_APP_PROXY_ADDRESS,  process.env.REACT_APP_LOGINURL)).then(()=>{
      //console.log(isLoading, isAuthenticated, user, isFetching);
      if (user && user.type === 1) {
        //console.log("inside checks", isLoading, isAuthenticated, user, isFetching);
        //history("/admin/expert-dashboard");
        history.navigate("/dashboards/expert-dashboard");
      } else if (user && user.type === 2) {
        //history("/admin/follower-dashboard");
        history.navigate("/dashboards/follower-dashboard");
    }
    });

/*    const { error, user, isAuthenticated, isFetching, isLoading } = userDetails;
    console.log(isLoading, error, isAuthenticated, user, isFetching);*/
    //console.log(error, user);
  };

  return (
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <FacebookIcon color="inherit" />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <GitHubIcon color="inherit" />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                  <GoogleIcon color="inherit" />
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput  onChange={(e) => handleChange(e)} type="email" label="Email" id="email" fullWidth />
              </MDBox>

              <MDBox mb={2}>
                <MDInput onChange={(e) => handleChange(e)} type="password" label="Password" id="password" fullWidth />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton onClick={(e) => login(e)} variant="gradient" color="info" fullWidth>
              Login
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Don&apos;t have an account?{" "}
              <MDTypography
                  component={Link}
                  to="/auth/user-registration"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
              >
                Sign up
              </MDTypography>
            </MDTypography>
          </MDBox>
            </MDBox>
          </MDBox>
            </Card>
      </BasicLayout>
  );
};
export default LoginPage;
