import { createContext } from 'react'
import {io} from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { newSignalAlert } from 'yolo/middleware/followerDashboard/actions';
//import {useCookies} from "react-cookie";

const WebSocketContext = createContext(null)

export { WebSocketContext }
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name*/
export default ({ children }) => {
    /*const [cookies] = useCookies(['user']);
    const PROXY_ADDRESS = cookies.PROXY_ADDRESS;
    const WEBSOCKET = cookies.WEBSOCKET;
    console.log("message from websocket", WEBSOCKET);*/
    //const WS_BASE = "swagger.traderyolo.com";
    //const WS_BASE = "http://localhost:9090/ws";
    let socket;
    let ws;

    const dispatch = useDispatch();

    const sendMessage = (message) => {
        const payload = {
            token: message
        }
        //console.log("message from websocket on send", payload);
        //socket.emit("event://send-message", JSON.stringify(payload));
        //not using any specific channel for now
        //socket.(JSON.stringify(payload));
        socket.connect();
        if(socket.connected){
            socket.emit("hello", payload);
            dispatch(newSignalAlert());
        }
    }

    if (!socket) {
        socket = io.connect();
        //console.log("socket status >>> ", socket);
/*        console.log("socket connected window.location.hostname", window.location.hostname);
        console.log("socket connected window.location.port", window.location.port);*/

        /*socket.on("event://get-message", (msg) => {*/
        //not using any channel name for now
        socket.onAny(msg => {
            const payload = JSON.parse(msg);
            console.log("message from websocket on receive", payload);
            if (payload === "refresh") {
                dispatch(newSignalAlert());
            }
        })
        ws = {
            socket: socket,
            sendMessage
        }
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}