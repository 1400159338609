import {useState, forwardRef, useImperativeHandle} from "react";
// @material-ui/core components
import InputAdornment from '@mui/material/InputAdornment';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";


const TradeStrategy = forwardRef((props, ref) => {

    const [tradeStrategyInterests, setTradeStrategyInterests] = useState("");
    const [tradeInstrumentsInterests, setTradeInstrumentsInterests] = useState("");
    const [tradeTypeInterests, setTradeTypeInterests] = useState("");
    const [overallAutoTradingEnabled, setOverallAutoTradingEnabled] = useState(false);
    useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        }, sendState: () => {
            return sendState();
        }, state: {
            tradeStrategyInterests, tradeInstrumentsInterests, tradeTypeInterests, overallAutoTradingEnabled,
        },
    }));
    const sendState = () => {
        return {
            tradeStrategyInterests, tradeInstrumentsInterests, tradeTypeInterests, overallAutoTradingEnabled,
        };
    };
    const isValidated = () => {
        return true;
    };

    const tradeStrategyInterestsDropdown = [{
        label: 'Ultra Long Term(more than 1 year)',
        id: 1
    }, {label: 'Long Term(3 months - 1 year)', id: 2}, {
        label: 'Medium Term(1-3 months)',
        id: 3
    }, {label: 'Short Term(1 week - 1 Month)', id: 4}, {label: 'Ultra Short Term(Intra day - 1 week)', id: 5}];
    const tradeTypeDropdown = [{label: 'Spreadbet', id: 1}, {label: 'Options', id: 2}, {
        label: 'Funds',
        id: 3
    }, {label: 'Stocks', id: 4}];
    const tradeInstrumentsDropdown = [{label: 'Precious Metals', id: 1}, {label: 'Funds', id: 2}, {
        label: 'Companies',
        id: 3
    }, {label: 'Currencies', id: 4}];

    return (<MDBox>
            <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                <MDBox mb={1}>
                    <MDTypography variant="h5" fontWeight="regular">
                        What are your trading interests?
                    </MDTypography>
                </MDBox>
                <MDTypography variant="body2" color="text">
                    This will help us recommend some trading experts who align with your interests
                </MDTypography>
            </MDBox>
            <Grid container justifyContent="center" spacing={1}>
                <Grid container item justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Are you looking for trade signals that are for long term hold or only day trading ones?"
                            placement="top">
                            <MDBox mb={3}>
                                <Autocomplete
                                    id="tradeStrategyInterests"
                                    options={tradeStrategyInterestsDropdown}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <MDInput {...params}
                                                                      label=" Interested in Long term investments or Short term trades?"
                                                                      placeholder="IG"
                                                                      variant="standard"/>}
                                    onChange={(event, value) => {
                                        /*                      console.log(value.id);
                                                              console.log(event);*/
                                        setTradeStrategyInterests(value.id);
                                    }}
                                    /*autoComplete="off"*/
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Which type of broker account do you like to place trades in?"
                                 placement="top">
                            <MDBox mb={3}>
                                <Autocomplete
                                    id="tradeTypeInterests"
                                    options={tradeTypeDropdown}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <MDInput {...params} label="Trades Types Interests"
                                                                      placeholder=""
                                                                      variant="standard"/>}
                                    onChange={(event, value) => {
                                        /*                      console.log(value.id);
                                                              console.log(event);*/
                                        setTradeTypeInterests(value.id);
                                    }}
                                    /*autoComplete="off"*/
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1}>
                <Grid container item justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip title="What kind of instruments are you interested to recieve trade signals for?"
                                 placement="top">
                            <MDBox mb={3}>
                                <Autocomplete
                                    id="tradeInstrumentsInterests"
                                    options={tradeInstrumentsDropdown}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <MDInput {...params} label="Trades Types Interests"
                                                                      placeholder=""
                                                                      variant="standard"/>}
                                    onChange={(event, value) => {
                                        /*                      console.log(value.id);
                                                              console.log(event);*/
                                        setTradeInstrumentsInterests(value.id);
                                    }}
                                    /*autoComplete="off"*/
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Auto Trading Enables the signals from experts to be auto traded on your chosen broker
                            platform"
                                 placement="top">
                            <MDBox mb={3}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch
                                            checked={overallAutoTradingEnabled}
                                            disabled
                                            onChange={() => setOverallAutoTradingEnabled(!overallAutoTradingEnabled)}
                                            inputProps={{'aria-label': 'controlled'}}
                                        />}
                                        label={overallAutoTradingEnabled ? "Auto Trading Enabled" : "Auto Trading Disabled"}
                                    />
                                </FormGroup>
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>);
});
TradeStrategy.displayName = "TradeStrategy";

export default TradeStrategy;
