import {useEffect, useState} from "react";
import {getAllExperts, followExpert, unFollowExpert} from "yolo/middleware/allExperts/actions";
import {refreshUserData} from "yolo/middleware/login/actions";
import {useSelector, useDispatch} from "react-redux";


// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ArtTrack from "@mui/icons-material/ArtTrack";
import Unfollow from "@mui/icons-material/Favorite";
import Follow from "@mui/icons-material/FavoriteBorder";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MuiTooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "yolo/components/AdminLayout/DashboardLayout";
import DashboardNavbar from "yolo/components/AdminLayout/Navbars/DashboardNavbar";
import Footer from "yolo/components/AdminLayout/Footer";
import BookingCard from "examples/Cards/BookingCard";

// Images
import defaultImage from "assets/images/default-avatar.png";


const AllExperts = () => {

  const dispatch = useDispatch();

  const [isAllExpertsRefreshed, setIsAllExpertsRefreshed] = useState(false);
  const useAllExperts = useSelector((state) => ({
    user: state.loginState.user,
    allExperts: state.allExpertsState.allExperts,
    isLoading: state.allExpertsState.isLoading,
    isFetching: state.allExpertsState.isFetching,
    error: state.allExpertsState.error,
  }));
  const {user, allExperts, error, isLoading} = useAllExperts;

    const [count, setCount] = useState(1);
    const [refresh, setRefresh] = useState(1);

    useEffect(() => {
        if(refresh === 0){
            setRefresh(1);
        }
    }, [refresh])

    useEffect(() => {
        setRefresh(0);
    }, [count]);


  useEffect(() => {
      dispatch(getAllExperts(process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETALLEXPERTS));
        /*.then(() => {
      console.log("all Experts>>>1", allExperts);
    });*/
  }, [dispatch]);


  useEffect(() => {
    dispatch(refreshUserData(user.email, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETUSER))
        .then(() => {
            setCount(count+1);
        });
  }, [isAllExpertsRefreshed]);

  const unFollowExpertFunc = (e, expertEmail, followerEmail) => {
    e.preventDefault();
    /*console.log("signal ID is ", expertEmail, followerEmail);*/
    dispatch(unFollowExpert(expertEmail, followerEmail, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_UNFOLLOWEXPERT))
        .then(()=> {
              //console.log("the closing of signal is complete. Now refresh data");
              setIsAllExpertsRefreshed(!isAllExpertsRefreshed);
              /*refreshSignalData();*/
            }
        );
  }

  const followExpertFunc = (e, expertEmail, followerEmail) => {
    e.preventDefault();
    //console.log("signal ID is ", expertEmail, followerEmail);
    dispatch(followExpert(expertEmail, followerEmail, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_FOLLOWEXPERT))
        .then(()=> {
              //console.log("the closing of signal is complete. Now refresh data");
              setIsAllExpertsRefreshed(!isAllExpertsRefreshed);
              /*refreshSignalData();*/
            }
        );
  }

  const getDescription = (tradeSignalsCount, followerCount, profitability) => {
      const description = "has been on the platform since 2020. Their stats are -"+
          "- Generated "+tradeSignalsCount+" signals so far." +
          "- "+followerCount+" users following." +
          "- Their trade signals have generated "+profitability+"% in profits so far."
      return description;
  }

    const allExpertsDisplay = allExperts?allExperts.map(expert => {
            return (
                    <Grid item xs={12} md={6} lg={4}  key={expert.id}>
                        <MDBox mt={3}>
                            <BookingCard
                                image={defaultImage}
                                title={expert.name}
                                description= {getDescription(expert.tradeSignalsCount, expert.followerCount, expert.user.expert.profitability)}
                                price="Free Signals"
                                location="Barcelona, Spain"
                                action={
                                    <>
                                    <Tooltip title="Refresh" placement="bottom">
                                        <MDTypography
                                            variant="body1"
                                            color="primary"
                                            lineHeight={1}
                                            sx={{ cursor: "pointer", mx: 3 }}
                                        >
                                            <Icon color="inherit">refresh</Icon>
                                        </MDTypography>
                                    </Tooltip>
                                        {user.user.following && user.user.following.includes(expert.id)
                                            ?
                                            <Tooltip title="unfollow" placement="bottom">
                                                <MDTypography
                                                    variant="body1"
                                                    color="primary"
                                                    lineHeight={1}
                                                    sx={{cursor: "pointer", mx: 3}}
                                                    onClick={(e) => {
                                                        unFollowExpertFunc(e, expert.email, user.email);
                                                    }}
                                                >
                                                <Unfollow color="inherit">unfollow</Unfollow>
                                            </MDTypography>
                                            </Tooltip>
                                            :
                                            <Tooltip title="follow" placement="bottom">
                                                <MDTypography
                                                    variant="body1"
                                                    color="primary"
                                                    lineHeight={1}
                                                    sx={{cursor: "pointer", mx: 3}}
                                                    onClick={(e) => {
                                                        followExpertFunc(e, expert.email, user.email);
                                                    }}
                                                >
                                                <Follow color="inherit">follow</Follow>
                                            </MDTypography>
                                            </Tooltip>
                                        }
                                    </>
                                }
                            />
                        </MDBox>
                    </Grid>
            )}
        ):null;

  if (isLoading === true) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  return (
      <div>
          <DashboardLayout>
              <DashboardNavbar />
              {user.name} - Here you will find all the expert traders registered on the platform. Following any of them will give you free access to all the trading signals that they generated in real-time.
              <MDBox mt={2}>
                  <Grid container spacing={3}>
                      {allExpertsDisplay}
                  </Grid>
              </MDBox>
              <Footer />
          </DashboardLayout>
      </div>
  );
}
export default AllExperts;


/*
<GridContainer>
    {/!*{refresh ? <ChildComp allExpertsPass={allExperts}/> : null}*!/}
    {
        allExperts.map((expert) => (
            <GridItem xs={12} sm={12} md={4} key={expert.id}>
                <Card product className={classes.cardHover}>
                    <CardHeader image className={classes.cardHeaderHover}>
                        <a href="#pablo" >
                            <img src={priceImage1} alt="..." />
                        </a>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.cardHoverUnder}>
                            <Tooltip
                                id="tooltip-top"
                                title="View Details"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button color="transparent" simple justIcon >
                                    <ArtTrack className={classes.underChartIcons} />
                                </Button>
                            </Tooltip>
                            {user.user.following && user.user.following.includes(expert.id)
                                ?
                                <Tooltip
                                    id="tooltip-top"
                                    title="UnFollow"
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button color="danger" simple justIcon onClick={(e) => {
                                        unFollowExpertFunc(e, expert.email, user.email);
                                    }}>
                                        <Unfollow className={classes.underChartIcons} />
                                    </Button>
                                </Tooltip>
                                :
                                <Tooltip
                                    id="tooltip-top"
                                    title="Follow"
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button color="danger" simple justIcon onClick={(e) => {
                                        followExpertFunc(e, expert.email, user.email);
                                    }}>
                                        <Follow className={classes.underChartIcons} />
                                    </Button>
                                </Tooltip>

                            }
                        </div>
                        <h4 className={classes.cardProductTitle}>
                            <a href="#pablo">
                                {expert.name}
                            </a>
                        </h4>
                        <p className={classes.left}>
                            has been on the platform since 2020. Their stats are - <br/>
                            - Generated <b>{expert.tradeSignalsCount}</b> signals so far. <br/>
                            - <b>{expert.followerCount}</b> users following. <br/>
                            - Their trade signals have generated <b>{expert.user.expert.profitability}%</b> in profits so far.
                        </p>
                    </CardBody>
                    <CardFooter product>
                        <div className={classes.price}>
                            <h4>Free Signals</h4>
                        </div>
                        <div className={`${classes.stats} ${classes.productStats}`}>
                            <Place /> {expert.location}
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
        ))
    }
</GridContainer>*/

/*<GridContainer>
    <div id="smart-button-container">
        <div style="text-align: center;">
            <div style="margin-bottom: 1.25rem;">
                <p></p>
                <select id="item-options"><option value="Meh!" price="5">Meh! - 5 USD</option><option value="I made some money!" price="10">I made some money! - 10 USD</option><option value="Keep em signals comin!" price="20">Keep em signals comin! - 20 USD</option><option value="Made Lottsa Mullah!" price="50">Made Lottsa Mullah! - 50 USD</option></select>
                <select style="visibility: hidden" id="quantitySelect"></select>
            </div>
            <div id="paypal-button-container"></div>
        </div>
    </div>
</GridContainer>
<script src="https://www.paypal.com/sdk/js?client-id=sb&enable-funding=venmo&currency=USD" data-sdk-integration-source="button-factory"></script>
<script>
    function initPayPalButton() {
    var shipping = 0;
    var itemOptions = document.querySelector("#smart-button-container #item-options");
    var quantity = parseInt();
    var quantitySelect = document.querySelector("#smart-button-container #quantitySelect");
    if (!isNaN(quantity)) {
    quantitySelect.style.visibility = "visible";
}
    var orderDescription = '';
    if(orderDescription === '') {
    orderDescription = 'Item';
}
    paypal.Buttons({
    style: {
    shape: 'rect',
    color: 'gold',
    layout: 'vertical',
    label: 'paypal',

},
    createOrder: function(data, actions) {
    var selectedItemDescription = itemOptions.options[itemOptions.selectedIndex].value;
    var selectedItemPrice = parseFloat(itemOptions.options[itemOptions.selectedIndex].getAttribute("price"));
    var tax = (0 === 0 || false) ? 0 : (selectedItemPrice * (parseFloat(0)/100));
    if(quantitySelect.options.length > 0) {
    quantity = parseInt(quantitySelect.options[quantitySelect.selectedIndex].value);
} else {
    quantity = 1;
}

    tax *= quantity;
    tax = Math.round(tax * 100) / 100;
    var priceTotal = quantity * selectedItemPrice + parseFloat(shipping) + tax;
    priceTotal = Math.round(priceTotal * 100) / 100;
    var itemTotalValue = Math.round((selectedItemPrice * quantity) * 100) / 100;

    return actions.order.create({
    purchase_units: [{
    description: orderDescription,
    amount: {
    currency_code: 'USD',
    value: priceTotal,
    breakdown: {
    item_total: {
    currency_code: 'USD',
    value: itemTotalValue,
},
    shipping: {
    currency_code: 'USD',
    value: shipping,
},
    tax_total: {
    currency_code: 'USD',
    value: tax,
}
}
},
    items: [{
    name: selectedItemDescription,
    unit_amount: {
    currency_code: 'USD',
    value: selectedItemPrice,
},
    quantity: quantity
}]
}]
});
},
    onApprove: function(data, actions) {
    return actions.order.capture().then(function(orderData) {

    // Full available details
    console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

    // Show a success message within this page, e.g.
    const element = document.getElementById('paypal-button-container');
    element.innerHTML = '';
    element.innerHTML = '<h3>Thank you for your payment!</h3>';

    // Or go to another URL:  actions.redirect('thank_you.html');

});
},
    onError: function(err) {
    console.log(err);
},
}).render('#paypal-button-container');
}
    initPayPalButton();
</script>*/
