export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS";
export const RECEIVE_NOTIFICATIONS = "RECEIVE_NOTIFICATIONS";
export const ERROR = "ERROR";
export const REQUEST_ALL_EXPERT_SIGNALS = "REQUEST_ALL_EXPERT_SIGNALS";
export const RECEIVE_ALL_EXPERT_SIGNALS = "RECEIVE_ALL_EXPERT_SIGNALS";
export const REQUEST_ALL_FOLLOWER_SIGNALS = "REQUEST_ALL_FOLLOWER_SIGNALS";
export const RECEIVE_ALL_FOLLOWER_SIGNALS = "RECEIVE_ALL_FOLLOWER_SIGNALS";
export const REQUEST_CLOSE_EXISTING_SIGNAL = "REQUEST_CLOSE_EXISTING_SIGNAL";
export const RECEIVE_CLOSE_EXISTING_SIGNAL = "RECEIVE_CLOSE_EXISTING_SIGNAL";
export const RECEIVE_CLOSE_EXISTING_SIGNAL_ERROR = "RECEIVE_CLOSE_EXISTING_SIGNAL";
export const RECEIVE_STOCK_OPTIONS_EXPIRY_DATE = "RECEIVE_STOCK_OPTIONS_EXPIRY_DATE";
export const RECEIVE_STOCK_OPTIONS_PCR_MAXPAIN = "RECEIVE_STOCK_OPTIONS_PCR_MAXPAIN";
export const RECEIVE_STOCK_OPTIONS_STRATEGIES = "RECEIVE_STOCK_OPTIONS_STRATEGIES";
export const REQUEST_STOCK_OPTIONS_STRATEGIES = "REQUEST_STOCK_OPTIONS_STRATEGIES";
export const RECEIVE_TICKER_INFO = "RECEIVE_TICKER_INFO";

