//Common stuff
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {registerUser} from "yolo/middleware/register/actions";
import {history} from '_helpers/history';
// Material Dashboard 2 PRO React examples
// Authentication layout components
import BasicLayout from "yolo/components/AuthLayout/BasicLayout";
import Footer from "yolo/components/AdminLayout/Footer";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// Wizard page components
import AboutYou from "./steps/AboutYou.jsx";
import BrokerDetails from "./steps/BrokerDetails.jsx";
import TradeStrategy from "./steps/TradeStrategy.jsx";


//ForCheckbox
import Check from "@mui/icons-material/Check";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";


function getSteps() {
    return ["About You", "Broker Details", "Trade Strategy"];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <AboutYou/>;
        case 1:
            return <BrokerDetails/>;
        case 2:
            return <TradeStrategy/>;
        default:
            return null;
    }
}


export default function WizardView() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;

    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);

    const dispatch = useDispatch();

    let registrationDetails = useSelector((state) => ({
        isLoading: state.registerState.isLoading,
        isFetching: state.registerState.isFetching,
        isAuthenticated: state.registerState.isAuthenticated,
        error: state.registerState.error,
        user: state.registerState.user,
        token: state.registerState.token,
        imageFileType: state.registerState.imageFileType,
        imageFileBytes: state.registerState.imageFileBytes,
    }));
    const {token, imageFileType, imageFileBytes} = registrationDetails;
    let {user} = registrationDetails;
    const [userToRegister, setUserToRegister] = useState(user);
    const [tandcErrorMsg, setTandcErrorMsg] = useState("");
    const [tandcagreed, setTandcagreed] = useState(false);

    const register = (e) => {
        /*console.log("this is value of reg", e);*/
        if (tandcagreed) {
            //setUserToRegister(user);
            let userToRegisterStateSoFar = userToRegister;
            userToRegisterStateSoFar.title = e.AboutYou.title === "2" ? "Mr" : "Mrs";
            userToRegisterStateSoFar.firstName = e.AboutYou.firstname;
            userToRegisterStateSoFar.lastName = e.AboutYou.lastname;
            userToRegisterStateSoFar.type = 2;
            userToRegisterStateSoFar.username = e.AboutYou.email.split(/[,@]+/)[0]; //taking the first word beofre either . or @
            userToRegisterStateSoFar.email = e.AboutYou.email;
            userToRegisterStateSoFar.password = e.AboutYou.password;
            userToRegisterStateSoFar.passwordConfirmation = e.AboutYou.password;
            userToRegisterStateSoFar.contactNumber = 0;
            /*userToRegisterStateSoFar.contactNumber = e.AboutYou.contactNumber;*/
            //console.log("this is country",e.AboutYou.countryOfResidence==="2");
            userToRegisterStateSoFar.countryOfResidence = e.AboutYou.countryOfResidence === "2" ? "UK" : e.AboutYou.countryOfResidence === "3" ? "IN" : "USA";

            userToRegisterStateSoFar.follower.overallAutoTradingEnabled = e.TradeStrategy.overallAutoTradingEnabled;
            userToRegisterStateSoFar.follower.tradeStrategyInterests = [];
            e.TradeStrategy.tradeStrategyInterests ? userToRegisterStateSoFar.follower.tradeStrategyInterests.push(e.TradeStrategy.tradeStrategyInterests) : null;
            userToRegisterStateSoFar.follower.tradeInstrumentsInterests = [];
            e.TradeStrategy.tradeInstrumentsInterests ? userToRegisterStateSoFar.follower.tradeInstrumentsInterests.push(e.TradeStrategy.tradeInstrumentsInterests) : null;
            userToRegisterStateSoFar.follower.tradeTypeInterests = [];
            e.TradeStrategy.tradeTypeInterests ? userToRegisterStateSoFar.follower.tradeTypeInterests.push(e.TradeStrategy.tradeTypeInterests) : null;
            userToRegisterStateSoFar.follower.brokerId = e.BrokerDetails.brokerId ? parseInt(e.BrokerDetails.brokerId) : 0;
            //console.log("this is broker ID", e.BrokerDetails.brokerId?parseInt(e.BrokerDetails.brokerId):0, e.BrokerDetails.brokerId);
            userToRegisterStateSoFar.follower.brokers = [];
            if (userToRegisterStateSoFar.follower.brokerId > 0) {
                let broker = {
                    liveAccount: e.BrokerDetails.liveAccount, //Is this a live account if not then its a demo account
                    name: e.BrokerDetails.brokerId === "1" ? "IG" : "Invalid",
                    spreadbetAccountId: e.BrokerDetails.spreadbetAccountId,
                    cfdAccountId: e.BrokerDetails.cfdAccountId,
                    stocksSharesAccountId: e.BrokerDetails.stocksSharesAccountId,
                    autoTradingEnabled: e.BrokerDetails.autoTradingEnabled,
                    apiKey: e.BrokerDetails.apiKey,
                    apiUserName: e.BrokerDetails.apiUserName,
                    apiPwd: e.BrokerDetails.apiPwd,
                }
                userToRegisterStateSoFar.follower.brokers.push(broker);
            }
            /*userToRegisterStateSoFar.follower.brokers[0].liveAccount = e.BrokerDetails.liveAccount;
            userToRegisterStateSoFar.follower.brokers[0].name = e.BrokerDetails.brokerId === 1?"IG":"Invalid";
            userToRegisterStateSoFar.follower.brokers[0].spreadbetAccountId = e.BrokerDetails.spreadbetAccountId;
            userToRegisterStateSoFar.follower.brokers[0].cfdAccountId = e.BrokerDetails.cfdAccountId;
            userToRegisterStateSoFar.follower.brokers[0].stocksSharesAccountId = e.BrokerDetails.stocksSharesAccountId;
            userToRegisterStateSoFar.follower.brokers[0].autoTradingEnabled = e.BrokerDetails.autoTradingEnabled;
            userToRegisterStateSoFar.follower.brokers[0].apiKey = e.BrokerDetails.apiKey;
            userToRegisterStateSoFar.follower.brokers[0].apiUserName = e.BrokerDetails.apiUserName;
            userToRegisterStateSoFar.follower.brokers[0].apiPwd = e.BrokerDetails.apiPwd;*/
            setUserToRegister(userToRegisterStateSoFar);
            dispatch(registerUser(userToRegister, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_REGISTERFOLLOWERURL)).then(() => {
                if (token !== "") {
                    //console.log("inside checks", isLoading, isAuthenticated, user, isFetching);
                    history.navigate("/auth/login-page");
                } else {
                    console.log("some error while registering user");
                }
            });
        } else {
            setTandcErrorMsg("Please read and agree to our Terms and Conditions.");
        }
    };

    /*const setName = (name) => {
        console.log("some set Name ", name);
        let userToRegisterStateSoFar = userToRegister;
        userToRegisterStateSoFar.name(name);
        setUserToRegister(userToRegister);
    };*/

    const handleToggle = () => {
        setTandcagreed(!tandcagreed);
    };
    useEffect(() => {
        if (tandcagreed) {
            setTandcErrorMsg("");
        }
    }, [tandcagreed]);


    useEffect(() => {
        if (imageFileBytes != null) {
            //console.log("this is the image file received", imageFileType);
            //let userToRegisterStateSoFar = userToRegister;
            //userToRegisterStateSoFar.image.type = imageFileType;
            //userToRegisterStateSoFar.image.default = imageFileBytes.toString().split(",").pop();
            //let imageString = imageFileBytes.toString().split(",").pop();
            //userToRegisterStateSoFar.image.default = new Blob([Buffer.from(imageString,'utf-8')]);
            //userToRegisterStateSoFar.image.default = imageFileBytes.toString().split(",").pop();
            //userToRegisterStateSoFar.image = new Blob([imageFileBytes]);
            // setUserToRegister(userToRegisterStateSoFar);
        }
    }, [imageFileBytes]);


    return (
        <BasicLayout image={bgImage}>
            <Card sx={{ justifySelf: "centre", minWidth: 900 }}>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Sign-up
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Register to unlock the full potential of the Yolo Trading Platform.
                    </MDTypography>
                </MDBox>
                <MDBox mt={-3} mx={2}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </MDBox>
                <MDBox p={2}>
                    <MDBox>
                        {getStepContent(activeStep)}
                        <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            {activeStep === 0 ? (
                                <MDBox/>
                            ) : (
                                <MDButton variant="outlined" color="dark" onClick={handleBack}>
                                    back
                                </MDButton>
                            )}
                            <MDButton
                                variant="gradient"
                                color="dark"
                                onClick={(e, value) =>{
                                    /*console.log(isLastStep);
                                    console.log(e, value);*/
                                    !isLastStep ? handleNext(e) : register(e)
                                }}
                            >
                                {isLastStep ? "Register" : "next"}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox mt={1} mb={1} textAlign="right">
                    <MDBox mb={0}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => handleToggle()}
                                    checkedIcon={
                                        <Check/>
                                    }
                                    icon={<Check/>}
                                />
                            }
                            label={
                                <span>I agree to the{" "}<a href="#pablo">terms and conditions</a>.</span>
                            }
                        />
                        <MDTypography variant="caption" fontWeight="light" color="error">
                            {tandcErrorMsg}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}
