import { combineReducers, } from "@reduxjs/toolkit";
import expertDashboardState from "../yolo/middleware/expertDashboard/reducer";
import followerDashboardState from "../yolo/middleware/followerDashboard/reducer";
import notificationsState from "../yolo/middleware/notifications/reducer";
import loginState from "../yolo/middleware/login/reducer";
import registerState from "../yolo/middleware/register/reducer";
import allExpertsState from "../yolo/middleware/allExperts/reducer";
import counter from './counter';

const rootReducer = combineReducers({
  loginState: loginState,
  expertDashboardState: expertDashboardState,
  followerDashboardState: followerDashboardState,
  registerState: registerState,
  notificationsState: notificationsState,
  allExpertsState: allExpertsState,
  counter: counter,
});

export default rootReducer;
