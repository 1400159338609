//require('dotenv').config();
import * as constant from "./constants";
import axios from "axios";
import MDButton from "components/MDButton";
import Close from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";


/*import {round} from "mathjs";*/
import {dateFromObjectId} from "../services/Utils"
/*const requestDashboard = () => {
  return {
    type: constant.REQUEST_DASHBOARD,
  };
};
const receiveDashboard = (dashboard) => {
  return {
    type: constant.RECEIVE_DASHBOARD,
    dashboard: dashboard,
  };
};
const receiveError = (error) => {
  return {
    type: constant.ERROR,
    error: error,
  };
};*/

const requestAllExpertSignals = () => {
    return {
        type: constant.REQUEST_ALL_EXPERT_SIGNALS,
    };
};
const receiveAllExpertSignals = (signals, allOpenTradeSignals, signalTableData, signalTableHeads, totalSignals, signalsClosedInProfit, msg) => {
    return {
        type: constant.RECEIVE_ALL_EXPERT_SIGNALS,
        allSignalsForExpert: signals,
        signalTableData: signalTableData,
        signalTableHeads: signalTableHeads,
        totalSignals: totalSignals,
        signalsClosedInProfit: signalsClosedInProfit,
        allOpenTradeSignals: allOpenTradeSignals,
        getAllSignalsError: msg,
    };
};
/*const receiveSignalTableData = (signalTableData) => {
    return {
        type: constant.RECEIVE_SIGNALS_TABLE_DATA,
        signalTableData: signalTableData,
    };
};*/
const receiveGetAllSignalsError = (error) => {
    return {
        type: constant.RECEIVE_GET_ALL_SIGNALS_ERROR,
        getAllSignalsError: error,
    };
};


const getAllSignalsForExpert = (closeFunction, expertId, expertEmail, PROXY_ADDRESS, GETALLSIGNALSFOREXPERTURL) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    const getAllExpertSignalsRequest = {
        expertId: expertId
    };
    return async (dispatch) => {
        try {
            dispatch(requestAllExpertSignals());
            //console.log("RESPONSE allSignalsCount for all expert allSignalsCount: >>> ", PROXY_ADDRESS + GETALLSIGNALSFOREXPERTURL);
            let response = await axios.post(PROXY_ADDRESS + GETALLSIGNALSFOREXPERTURL, getAllExpertSignalsRequest, {headers: headers});
            let signalTableHeads = await populateSignalTableHeaderFooterElements();
            let signalTableData = {
                columns: [],
                rows: []
            };
            signalTableData.columns = signalTableHeads;
            let allOpenTradeSignals = [];
            let signalsClosedInProfit = 0;
            const allOpenTradeSignalsFromAPI = response.data.allOpenTradeSignals;
            const allSignalsCount = allOpenTradeSignalsFromAPI.length;
            //console.log("RESPONSE allSignalsCount for all expert allSignalsCount: >>> ", allSignalsCount);
            if (allSignalsCount > 0) {
                [signalTableData.rows, allOpenTradeSignals, signalsClosedInProfit] = await populateSignalTableElements(allOpenTradeSignalsFromAPI);
                let signalsPercentClosedInProfit = (signalsClosedInProfit / (allSignalsCount - allOpenTradeSignals.length)) * 100;
                signalTableData.rows = await updateSignalTableDataFunction(signalTableData.rows, closeFunction, expertId, expertEmail);
                dispatch(receiveAllExpertSignals(allOpenTradeSignalsFromAPI, allOpenTradeSignals, signalTableData, signalTableHeads, allSignalsCount, Math.round(signalsPercentClosedInProfit !== 0 ? signalsPercentClosedInProfit : 0), ""))
            } else {
                [signalTableData.rows, allOpenTradeSignals, signalsClosedInProfit] = await populateSignalTableElements(allOpenTradeSignalsFromAPI);
                signalsClosedInProfit = 0;
                signalTableData.rows = await updateSignalTableDataFunction(signalTableData.rows, closeFunction, expertId, expertEmail);
                let msg = "No Trade Signals found. Why not create one Trade Signal either via email or the form on the left.";
                dispatch(receiveAllExpertSignals(allOpenTradeSignalsFromAPI, allOpenTradeSignals, signalTableData, signalTableHeads, allSignalsCount, signalsClosedInProfit, msg))
                //dispatch(receiveGetAllSignalsError("No Trade Signals found. Why not create one Trade Signal either via email or the form on the left."))
            }
        } catch (error) {
            //const message = error.response.statusText;
            console.log("this is the error", error)
            dispatch(receiveGetAllSignalsError(error));
        }
    }
};

/*const updateSignalTableData = (signalTableData, closeFunction, userId, expertEmail) => {
    return async (dispatch) => {
        try {
            //console.log("inside update table dispatch");
            signalTableData.dataRows = updateSignalTableDataFunction(signalTableData.dataRows, closeFunction, userId, expertEmail);
            //console.log("inside update table dispatch - after updating table with buttons", dataRows);
            dispatch(receiveSignalTableData(signalTableData));
        } catch (error) {
            dispatch(receiveError(error.toString()));
        }
    }
}*/

async function populateSignalTableElements(allTradeSignalsFromAPI) {
    let dataRows = [];
    let allOpenTradeSignals = [];
    let signalsClosedInProfit = 0;
    await allTradeSignalsFromAPI.map(signal => {
        if (!signal.tradeStillOpen) {
            if (signal.signalPerformance > 0) {
                ++signalsClosedInProfit
            }
            let tradeSignal = [];
            let date = dateFromObjectId(signal.signalId);
            tradeSignal.push(date.toString());
            tradeSignal.push(signal.instrumentId);
            tradeSignal.push(signal.signalSuggestedTradeDirection);
            tradeSignal.push(signal.spotPriceWhenSignalInitiated);
            tradeSignal.push(signal.spotPriceWhenSignalClosed);
            tradeSignal.push(signal.signalId);
            allOpenTradeSignals.push(tradeSignal);
        }
        let tradeSignal = [];
        let date = dateFromObjectId(signal.signalId);
        tradeSignal.push(date.toString());              //0
        tradeSignal.push(signal.instrumentId);          //1
        tradeSignal.push(signal.suggestedTradeDirection);   //2
        tradeSignal.push(signal.spotPriceWhenSignalInitiated);  //3
        tradeSignal.push(signal.spotPriceWhenSignalClosed); //4
        tradeSignal.push(signal.signalId);                  //5
        tradeSignal.push(signal.tradeStillOpen);            //6
        tradeSignal.push(signal.signalPerformance !== 0.00 ? Math.round(signal.signalPerformance * 100) / 100 : 0).toFixed(2);//7
        tradeSignal.push(signal.signalSuggestedExecutionPrice);  //8
        tradeSignal.push(signal.signalSuggestedLimitPrice); //9
        tradeSignal.push(signal.signalSuggestedStopLossPrice); //10
        tradeSignal.push(signal.signalTradedByFollowersCount); //11
        tradeSignal.push(signal.signalIgnoredByFollowersCount); //12
        dataRows.push(tradeSignal);
    });
    return [dataRows, allOpenTradeSignals, signalsClosedInProfit];
}

async function populateSignalTableHeaderFooterElements() {
    return ([
        {
            Header: "Origin Date",
            accessor: "originDate",
            width: "10%"
        },
        {
            Header: "Instrument ID",
            accessor: "instrumentId",
            width: "10%"
        },
        {
            Header: "Trade Direction",
            accessor: "suggestedTradeDirection",
            width: "10%"
        },
        {
            Header: "Opening Price",
            accessor: "signalSuggestedExecutionPrice",
            width: "10%"
        },
        {
            Header: "Closing Price",
            accessor: "signalSuggestedLimitPrice",
            width: "10%"
        },
        {
            Header: "Stop Loss Price",
            accessor: "signalSuggestedStopLossPrice",
            width: "10%"
        },
        {
            Header: "Signal P&L",
            accessor: "signalPerformance",
            width: "10%"
        },
        {
            Header: "Ignored By",
            accessor: "signalIgnoredByFollowersCount",
            width: "10%"
        },
        {
            Header: "Traded By",
            accessor: "signalTradedByFollowersCount",
            width: "10%"
        },
        {
            Header: "Close Signal",
            accessor: "actions",
            width: "10%"
        },
        /* {
             Header: "SignalID",
             accessor: "signalId",
         },*/
    ]);
}

async function updateSignalTableDataFunction(dataRows, closeFunction, userId, expertEmail) {
    let data = [];
    dataRows.map((prop, key) => {
        let dataRow = {
            id: key,
            originDate: prop[0],
            instrumentId: prop[1],
            suggestedTradeDirection: prop[2],
            spotPriceWhenSignalInitiated: prop[3],
            spotPriceWhenSignalClosed: prop[6] ? "Signal Still Open" : prop[4],
            signalPerformance: prop[6] ? "Signal Still Open" : prop[7] + "%",
            signalId: prop[5],
            tradeStillOpen: prop[6],
            signalSuggestedExecutionPrice: prop[8],
            signalSuggestedLimitPrice: prop[9],
            signalSuggestedStopLossPrice: prop[10],
            signalTradedByFollowersCount: prop[11] ? prop[11] : 0,
            signalIgnoredByFollowersCount: prop[12] ? prop[12] : 0,
            actions: (
                // we've added some custom button actions
                <div>
                    {prop[6] ?
                        <Tooltip
                            id="tooltip-top"
                            title="Close Signal"
                            placement="bottom"
                        >
                            <MDButton
                                onClick={() => {
                                    //console.log("this is the number array in which we found signal to close");
                                    //var newData = data;
                                    /*dataRows.find((o, i) => {
                                        console.log("this is the number array in which we found signal to close", dataRows, key, i, dataRow);
                                        if (i === key) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            //console.log(o.signalId, o.instrumentId);*/
                                    //console.log("this is the number array in which we found signal to close");
                                    closeFunction(dataRow.signalId, userId, dataRow.instrumentId, expertEmail);
                                    return true;
                                    /*!//o.tradeStillOpen = false;
                                    //delete the current o and replace it with new
                                    //newData.splice(i,1,o);
                                    return true;
                                }
                                return false;
                            });*/
                                    //setData([...newData])
                                }}
                            >
                                <Close/>
                            </MDButton>
                        </Tooltip>
                        :
                        "Signal Closed"}
                </div>
            ),
        }
        data.push(dataRow)
    });
    return data;
}

export {getAllSignalsForExpert /*updateSignalTableData*/};
