import * as constant from "./constants";
/*import appConfig from "../config";*/
import axios from "axios";


const requestAllExperts = () => {
  return {
    type: constant.ALL_EXPERTS_REQUEST,
  };
};
const receiveAllExperts = (allExperts) => {
  return {
    type: constant.ALL_EXPERTS_SUCCESS,
    allExperts: allExperts,
  };
};
const receiveError = (error) => {
  return {
    type: constant.ALL_EXPERTS_FAILURE,
    error: error,
  };
};

const requestFollowExpert = () => {
  return {
    type: constant.FOLLOW_EXPERTS_REQUEST,
  };
};
const receiveFollowExpert = (followerCount) => {
  return {
    type: constant.FOLLOW_EXPERTS_SUCCESS,
    followerCount: followerCount,
  };
};
const receiveFollowExpertError = (error) => {
  return {
    type: constant.FOLLOW_EXPERTS_FAILURE,
    error: error,
  };
};


const getAllExperts = (PROXY_ADDRESS, GETALLEXPERTS) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  return async (dispatch) => {
    try {
      dispatch(requestAllExperts());
      let response = await axios.get(PROXY_ADDRESS + GETALLEXPERTS, {
        headers: headers
      })
          /*.then((response) => {*/
            /*console.log("RESPONSE RECEIVED: >>> ", response);*/
            dispatch(receiveAllExperts(response.data.experts))
          /*}).catch((error) => {
            console.log("AXIOS ERROR: ", error.toString());
            dispatch(receiveError(error))
          })*/
    } catch (error) {
      console.log("CATCH ERROR: ", error.toString());
      dispatch(receiveError(error.toString()));
    }
  };
};

const unFollowExpert = (expertEmail, followerEmail, PROXY_ADDRESS, UNFOLLOWEXPERT) => {

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const followExpertRequest = {
    followerEmail: followerEmail,
    expertEmail: expertEmail,
  };
  return async (dispatch) => {
    try {
      dispatch(requestFollowExpert());
      let res = await axios.post(PROXY_ADDRESS + UNFOLLOWEXPERT,  followExpertRequest, {
        headers: headers
      })
      //console.log("this is the trade confirmation", res.data.tradeConfirmationId)
      dispatch(receiveFollowExpert(res.data));
    } catch (error) {
      const message = error.response.data;
      dispatch(receiveFollowExpertError(message));
    }
  };
};

const followExpert = (expertEmail, followerEmail, PROXY_ADDRESS, FOLLOWEXPERT) => {

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const followExpertRequest = {
    followerEmail: followerEmail,
    expertEmail: expertEmail,
  };
  return async (dispatch) => {
    try {
      dispatch(requestFollowExpert());
      let res = await axios.post(PROXY_ADDRESS + FOLLOWEXPERT,  followExpertRequest, {
        headers: headers
      })
      //console.log("this is the trade confirmation", res.data.tradeConfirmationId)
      dispatch(receiveFollowExpert(res.data));
    } catch (error) {
      const message = error.response.data;
      dispatch(receiveFollowExpertError(message));
    }
  };
};



export {getAllExperts, unFollowExpert, followExpert};