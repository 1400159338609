import * as constant from "./constants";


const initialState = {
  isLoading: false,
  fetchingAllSignals: false,
  error: null,
  getAllSignalsError: null,
  executeTradeSignalError: null,
  executingTradeSignal: false,
  tradeConfirmationDetails: null,
  newSignalAlert:false,
  dashboard: {
    signals: {
      signalsPerformance: {
        isLoading: false,
        LastWeek: [],
        LastMonth: [],
        LastYear: [],
        lastUpdated: ""
      },
      executedTradesPerformance: {
        isLoading: false,
        LastWeek: [],
        LastMonth: [],
        LastYear: [],
        lastSignalSentDateTime: ""
      },
      followersExecutedNoOfTradeSignals: {
        isLoading: false,
        LastWeek: [],
        LastMonth: [],
        LastYear: [],
        lastSignalExecutedFromYoloPlatform: ""
      },
    },
  },
  signalTableHeads: [],
  signalTableDataRows: [],
  signalTableData: {
    columns: [],
    rows:[]
  },
  allOpenTradeSignals: [],
  expertsFollowing: 0,
  totalExpertsOnPlatform: 0,
  totalSignalsReceived: 0,
  signalsExecutedViaPlatform: 0,
  signalsClosedInProfit: 0,
  dollarOneInvestedNowIs:0,
  tradeConfirmationId: "",
  allSignalsForFollower: [],
  instrumentDetailsFromBroker: null,
  loadingExecutionDialogModal: false,
};
const followerDashboardState = (state = initialState, action) => {
  switch (action.type) {

    case constant.ERROR: {
      return { ...state, isLoading: false, error: action.error };
    }

    case constant.REQUEST_ALL_FOLLOWER_SIGNALS: {
      return {
        ...state,
        fetchingAllSignals: true,
      };
    }

    case constant.RECEIVE_ALL_FOLLOWER_SIGNALS: {
      return {
        ...state,
        fetchingAllSignals: false,
        allSignalsForFollower: action.allSignalsForFollower,
        allOpenTradeSignals: action.allOpenTradeSignals,
        signalTableData: action.signalTableData,
        signalTableHeads: action.signalTableHeads,
        signalTableDataRows: action.signalTableDataRows,
        dashboard: {
          signals : {
            totalSignals: action.totalSignals,
            signalsClosedInProfit: action.signalsClosedInProfit,
            dollarOneInvestedNowIs:1.3,
          }
        },
        getAllSignalsError: action.getAllSignalsError,
      };
    }
    case constant.RECEIVE_GET_ALL_SIGNALS_ERROR: {
      return {
        ...state,
        fetchingAllSignals: false,
        getAllSignalsError: action.getAllSignalsError,
      };
    }
    case constant.RECEIVE_EXECUTE_SIGNAL_ERROR: {
      return {
        ...state,
        executingTradeSignal: false,
        executeTradeSignalError: action.executeTradeSignalError,
        instrumentDetailsFromBroker: null,
        loadingExecutionDialogModal: false,
      };
    }
    case constant.REQUEST_EXECUTE_SIGNAL: {
      return {
        ...state,
        executingTradeSignal: true,
        loadingExecutionDialogModal: true,
      };
    }
    case constant.RECEIVE_EXECUTE_SIGNAL: {
      return {
        ...state,
        executingTradeSignal: false,
        tradeConfirmationId: action.tradeConfirmationId,
        loadingExecutionDialogModal: false,
      };
    }
    case constant.RECEIVE_SIGNAL_TABLE_DATAROW: {
      return {
        ...state,
        signalTableData: action.signalTableData,
      };
    }
    case constant.RECEIVE_TRADE_CONFIRMATION_DETAILS: {
      return {
        ...state,
        tradeConfirmationDetails: action.tradeConfirmationDetails,
      };
    }
    case constant.RECEIVE_TRADE_CONFIRMATION_ERROR: {
      return {
        ...state,
        tradeConfirmationDetails: null,
        loadingExecutionDialogModal: false,
      };
    }
    case constant.RECEIVE_NEW_SIGNAL_ALERT: {
      return {
        ...state,
        newSignalAlert: true,
      };
    }
    case constant.REQUEST_INSTRUMENT_DETAILS_FOR_BROKER: {
      return {
        ...state,
        loadingExecutionDialogModal: true,
        //executingTradeSignal: true,
      };
    }
    case constant.RECEIVE_INSTRUMENT_DETAILS_FOR_BROKER: {
      return {
        ...state,
        //executingTradeSignal: false,
        instrumentDetailsFromBroker: action.instrumentDetailsFromBroker,
        loadingExecutionDialogModal: false,
      };
    }
    default:
      return state;
  }
};
export default followerDashboardState;
