import * as constant from "./constants";
import axios from 'axios'

function requestRegister() {
  return {
    type: constant.REGISTER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}
function receiveRegister(token) {
  return {
    type: constant.REGISTER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    token: token,
  };
}

function receiveImageFile(type, imageFileBytes) {
  return {
    type: constant.RECEIVE_IMAGE_FILE,
    imageFileType: type,
    imageFileBytes: imageFileBytes,
  };
}

function registerError(message) {
  return {
    type: constant.REGISTER_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    error: message,
  };
}

const registerUser = (userToRegister, PROXY_ADDRESS, REGISTERFOLLOWERURL) => {
  return async dispatch => {
    dispatch(requestRegister());
    //console.log(">>>>>>>>>>>>>>we are in service API for register", userToRegister);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    await axios.post(PROXY_ADDRESS + REGISTERFOLLOWERURL,  userToRegister, {
      headers: headers
    })
        .then((response) => {
          //console.log("RESPONSE RECEIVED: >>> ", response);
          dispatch(receiveRegister(response.data.token));
        }).catch((error) => {
          console.log("AXIOS ERROR: ", error);
          dispatch(registerError(error));
        });
  };
}

const setImageToState = (type, imageFileBytes) => {
  return async dispatch => {
    dispatch(receiveImageFile(type, imageFileBytes));
  }
}

export {registerUser, setImageToState};