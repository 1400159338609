//require('dotenv').config();
/*import jwt_decode from "jwt-decode";*/

export function dateFromObjectId(objectId) {
    let d = new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
     /*[d.getMonth()+1,
            d.getDate(),
            d.getFullYear()].join('/')+' '+
        [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');*/
    return d.toLocaleDateString(undefined, {day:'2-digit'}) + '-' + d.toLocaleDateString(undefined, {month:'short'}) + '-' + d.toLocaleDateString(undefined, {year:'numeric'}) + '--' + d.getHours() + ':' + d.getMinutes() + ':' +  d.getSeconds();
}

/*export function isAuthenticationValid(token) {
    // We check if app runs with backend mode
    //if (!config.isBackend && token) return true;
    console.log("inside is auth valid", token);
    if (!token) return;
    console.log("token is valid");
    //const date = new Date().getTime() / 1000;
    const data = jwt_decode.decode(token);
    console.log("data inside jwt token", data);
    /!*return date < data.exp;*!/
    return !(Date.now() >= data * 1000);
}*/
