import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from './_helpers/history';

export { PrivateRoute };

function PrivateRoute({ children }) {
    let userDetails = useSelector((state) => ({
        isAuthenticated: state.loginState.isAuthenticated,
/*        user: state.loginState.user,*/
    }));
    const { isAuthenticated } = userDetails;
    //console.log(isAuthenticated);

    if (!isAuthenticated) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/auth/login" state={{ from: history.location }} />
    }

    // authorized so return child components
    return children;
}