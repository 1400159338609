// @material-ui/core components
import Autocomplete from "@mui/material/Autocomplete";
/*import Datetime from "react-datetime";*/

// @material-ui/icons
import Email from "@mui/icons-material/Email";
import Face from "@mui/icons-material/Face";
import Lock from "@mui/icons-material/Lock";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
//import CustomInput from "components/CustomInput/CustomInput.js";

// core components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import {useState, forwardRef, useImperativeHandle} from "react";
//import PictureUpload from "yolo/components/CustomUpload/PictureUpload";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";
import team2 from "assets/images/team-2.jpg";
import InputAdornment from "@mui/material/InputAdornment";


const AboutYou = forwardRef((props, ref) => {
    const [title, setTitle] = useState("");
    const [firstname, setfirstname] = useState("");
    const [firstnameState, setfirstnameState] = useState("");
    const [password, setPassword] = useState("");
    const [passwordState, setPasswordState] = useState("");
    const [lastname, setlastname] = useState("");
    const [lastnameState, setlastnameState] = useState("");
    const [email, setemail] = useState("");
    const [contactNumber, setContactNumber] = useState(0);
    const [emailState, setemailState] = useState("");
    const [countryOfResidence, setCountryOfResidence] = useState("");

    useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        }, sendState: () => {
            return sendState();
        }, state: {
            title,
            firstname,
            firstnameState,
            lastname,
            lastnameState,
            email,
            emailState,
            contactNumber,
            countryOfResidence,
            password,
        },
    }));
    const sendState = () => {
        return {
            title,
            firstname,
            firstnameState,
            lastname,
            lastnameState,
            email,
            emailState,
            contactNumber,
            countryOfResidence,
            password,
        };
    };

    const isValidated = () => {
        if (firstnameState === "success" && lastnameState === "success" && emailState === "success" && passwordState === "success") {
            return true;
        } else {
            if (firstnameState !== "success") {
                setfirstnameState("error");
            }
            if (lastnameState !== "success") {
                setlastnameState("error");
            }
            if (emailState !== "success") {
                setemailState("error");
            }
            if (passwordState !== "success") {
                setPasswordState("error");
            }
        }
        return false;
    };

    // function that returns true if value is email, false otherwise
    const verifyEmail = (value) => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    // function that verifies if a string has a given length or not
    const verifyPassword = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const titlesDropdown = [{label: 'Mr', id: 1}, {label: 'Mrs', id: 2},];

    const nationalityDropdown = [{label: 'UK', id: 2}, {label: 'India', id: 3}, {label: 'USA', id: 4},];
    return (<MDBox>
            <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                <MDBox mb={1}>
                    <MDTypography variant="h5" fontWeight="regular">
                        Tell us a bit about yourself
                    </MDTypography>
                </MDBox>
                <MDTypography variant="body2" color="text">
                    Let us know your name and email address.
                </MDTypography>
            </MDBox>
            {/*<GridItem xs={12} sm={4}>
          <PictureUpload />
        </GridItem>*/}
            <MDBox mt={2} mb={4}>
                <Grid container justifyContent={"space-around"} spacing={8}>
                    <Grid container item xs={12} sm={4}>
                        <MDBox position="relative" height="max-content" mx="auto">
                            <MDAvatar src={team2} alt="profile picture" size="xxl" variant="rounded"/>
                            <MDBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                                <Tooltip title="Edit" placement="top">
                                    <MDButton variant="gradient" color="info" size="small" iconOnly>
                                        <Icon>edit</Icon>
                                    </MDButton>
                                </Tooltip>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container justifyContent="space-around" spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Your Title."
                            placement="top">
                            <MDBox mb={3}>
                                <Autocomplete
                                    options={titlesDropdown}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <MDInput {...params} label="Title" placeholder="Favorites"
                                                                      variant="standard"/>}
                                    onChange={(event, value) => {
                                        /*                      console.log(value.id);
                                                              console.log(event);*/
                                        setTitle(value.id);
                                    }}
                                    /*autoComplete="off"*/
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Enter a strong password."
                            placement="top">
                            <MDBox mb={3}>
                                <MDInput
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    type="password"
                                    onChange={(event) => {
                                        /*console.log(event.target.value);*/
                                        if (!verifyPassword(event.target.value, 3)) {
                                            setPasswordState("error");
                                        } else {
                                            setPasswordState("success");
                                        }
                                        setPassword(event.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><Lock/></InputAdornment>
                                    }}
                                    autoComplete="new-password"
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-around" spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Enter your first name"
                            placement="top">
                            <MDBox mb={3}>
                                <MDInput
                                    fullWidth
                                    id="firstname"
                                    label="First Name"
                                    type="firstname"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><Face/></InputAdornment>
                                    }}
                                    onChange={(event) => {
                                        if (!verifyLength(event.target.value, 3)) {
                                            setfirstnameState("error");
                                        } else {
                                            setfirstnameState("success");
                                        }
                                        setfirstname(event.target.value);
                                    }}
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Enter lastname."
                            placement="top">
                            <MDBox mb={3}>

                                <MDInput
                                    fullWidth
                                    id="lastname"
                                    label="Last Name"
                                    type="lastname"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><Face/></InputAdornment>
                                    }}
                                    onChange={(event) => {
                                        if (!verifyLength(event.target.value, 3)) {
                                            setlastnameState("error");
                                        } else {
                                            setlastnameState("success");
                                        }
                                        setlastname(event.target.value);
                                    }}
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Enter your contact number"
                            placement="top">
                            <MDBox mb={3}>
                                <MDInput
                                    fullWidth
                                    id="contactNumber"
                                    label="Contact Number"
                                    type="contactNumber"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><RecordVoiceOver/></InputAdornment>
                                    }}
                                    onChange={(event) => {
                                        setContactNumber(event.target.value)
                                    }}
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Enter your email address."
                            placement="top">
                            <MDBox mb={3}>
                                <MDInput
                                    fullWidth
                                    id="email"
                                    label="Email" /*<small>(required - this will be your login ID)</small>*/
                                    type="email"
                                    onChange={(event) => {
                                        if (!verifyEmail(event.target.value)) {
                                            setemailState("error");
                                        } else {
                                            setemailState("success");
                                        }
                                        setemail(event.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><Email/></InputAdornment>
                                    }}
                                    autoComplete="off"
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Enter your nationality."
                            placement="top">
                            <MDBox mb={3}>
                                <Autocomplete
                                    /*id="nationality"*/
                                    options={nationalityDropdown}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <MDInput {...params} label="Nationality"
                                                                      placeholder="Country Of Residence"
                                                                      variant="standard"/>}
                                    onChange={(event, value) => {
                                        setCountryOfResidence(value.id);
                                    }}
                                    /*autoComplete="off"*/
                                />
                            </MDBox>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>);
});

AboutYou.displayName = "AboutYou";

export default AboutYou;