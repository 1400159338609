import * as constant from "./constants";

const initialState = {
  isLoading: true,
  error: null,
  allExperts: null,
  isFetching: true,
  followerCount: 0,
};
const allExpertsState = (state = initialState, action) => {
  switch (action.type) {

    case constant.ALL_EXPERTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isFetching: true,
      };
    }

    case constant.ALL_EXPERTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: action.error
      };
    }

    case constant.ALL_EXPERTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: null,
        allExperts: action.allExperts,
      };
    }

    case constant.FOLLOW_EXPERTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isFetching: true,
      };
    }

    case constant.FOLLOW_EXPERTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: action.error
      };
    }

    case constant.FOLLOW_EXPERTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: null,
        followerCount: action.followerCount,
      };
    }


    default:
      return state;
  }
};
export default allExpertsState;
