export const ERROR = "ERROR";
export const REQUEST_ALL_FOLLOWER_SIGNALS = "REQUEST_ALL_FOLLOWER_SIGNALS";
export const RECEIVE_ALL_FOLLOWER_SIGNALS = "RECEIVE_ALL_FOLLOWER_SIGNALS";
export const RECEIVE_GET_ALL_SIGNALS_ERROR = "RECEIVE_GET_ALL_SIGNALS_ERROR";
export const RECEIVE_EXECUTE_SIGNAL_ERROR = "RECEIVE_EXECUTE_SIGNAL_ERROR";
export const RECEIVE_EXECUTE_SIGNAL = "RECEIVE_EXECUTE_SIGNAL";
export const REQUEST_EXECUTE_SIGNAL = "REQUEST_EXECUTE_SIGNAL";
export const RECEIVE_SIGNAL_TABLE_DATAROW = "RECEIVE_SIGNAL_TABLE_DATAROW";
export const RECEIVE_TRADE_CONFIRMATION_DETAILS = "RECEIVE_TRADE_CONFIRMATION_DETAILS";
export const RECEIVE_TRADE_CONFIRMATION_ERROR = "RECEIVE_TRADE_CONFIRMATION_ERROR";
export const RECEIVE_NEW_SIGNAL_ALERT = "RECEIVE_NEW_SIGNAL_ALERT";
export const RECEIVE_FOLLOWER_SIGNAL_TRADE_PREFS = "RECEIVE_FOLLOWER_SIGNAL_TRADE_PREFS";
export const REQUEST_FOLLOWER_SIGNAL_TRADE_PREFS = "REQUEST_FOLLOWER_SIGNAL_TRADE_PREFS";
export const RECEIVE_INSTRUMENT_DETAILS_FOR_BROKER = "RECEIVE_INSTRUMENT_DETAILS_FOR_BROKER";
export const REQUEST_INSTRUMENT_DETAILS_FOR_BROKER = "REQUEST_INSTRUMENT_DETAILS_FOR_BROKER";