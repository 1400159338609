import {useEffect, useState} from "react";
import {history} from '_helpers/history';
import {useDispatch, useSelector} from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable from "yolo/components/DataTable";
import Card from "@mui/material/Card";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "yolo/components/AdminLayout/DashboardLayout";
import DashboardNavbar from "yolo/components/AdminLayout/Navbars/DashboardNavbar";
import Footer from "yolo/components/AdminLayout/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";

import {getAllSignalsForExpert} from "yolo/middleware/expertDashboard/actions";
import {getStockOptionsExpiryDates, getStockPCRAndMaxPain, closeSignal, getTickerInfo} from "yolo/middleware/notifications/actions";

import reportsLineChartData from "../../../layouts/dashboards/analytics/data/reportsLineChartData";
// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import MDButton from "../../../components/MDButton";

export default function ExpertDashboard() {

    const [alert] = useState(null);
    const dispatch = useDispatch();
    const {tasks} = reportsLineChartData;

    const useDashboard = useSelector((state) => ({
        isDashboardLoading: state.expertDashboardState.isLoading,
        fetchingAllSignals: state.expertDashboardState.fetchingAllSignals,
        isNotificationLoading: state.notificationsState.isLoading,
        error: state.expertDashboardState.error,
        getAllSignalsError: state.expertDashboardState.getAllSignalsError,
        dashboard: state.expertDashboardState.dashboard,
        user: state.loginState.user,
        allSignalsForExpert: state.expertDashboardState.allSignalsForExpert,
        signalTableHeads: state.expertDashboardState.signalTableHeads,
        signalTableData: state.expertDashboardState.signalTableData,
        closeSignalError: state.notificationsState.closeSignalError,
        expiryDates: state.notificationsState.expiryDates,
        putCallRatio: state.notificationsState.putCallRatio,
        bufferLow: state.notificationsState.bufferLow,
        maxPain: state.notificationsState.maxPain,
        bufferHigh: state.notificationsState.bufferHigh,
        tickerInfo: state.notificationsState.tickerInfo,
    }));
    const [expiryDate, setExpiryDate] = useState("");
    const [instrumentId, setInstrumentId] = useState("");
    const {
        isDashboardLoading,
        fetchingAllSignals,
        error,
        user,
        dashboard,
        signalTableData,
        closeSignalError,
        getAllSignalsError,
        expiryDates,
        putCallRatio,
        bufferLow,
        bufferHigh,
        tickerInfo,
        maxPain
    } = useDashboard;
    //console.log("this is what we have in dashboard", allSignalsForExpert, signalTableHeads, signalTableData)

    //For Signal Form
    const [checked, setChecked] = useState([24, 22]);
    const [pageIndex, setPageIndex] = useState(0);
    /*  const [selectedEnabled, setSelectedEnabled] = React.useState("b");*/
    const [selectedValue, setSelectedValue] = useState(null);
    const [isSignalTableDataRefreshed, setIsSignalTableDataRefreshed] = useState(false);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    //For Signal Form
    const closeSignalForExpert = (signalId, expertId, instrumentId, expertEmail) => {
        // console.log("signal ID is ", signalId, expertId, instrumentId);
        dispatch(closeSignal(signalId, expertId, instrumentId, expertEmail, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_CLOSEEXISTINGTRADESIGNAL))
            .then(() => {
                //console.log("the closing of signal is complete. Now refresh data");
                /*refreshSignalData();*/
                setIsSignalTableDataRefreshed(!isSignalTableDataRefreshed);
            });
    }

    const handleChangeInstrumentId = (e) => {
        setInstrumentId(e.target.value);
    }

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const getStockOpExpiryDates = (e) => {
        setExpiryDate("");
        dispatch(getStockOptionsExpiryDates("", user.email, instrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETSTOCKOPTIONSEXPIRYDATES))
            .then(() => {
                    getTickerData(instrumentId);
                }
            );
    }

    const getPCRAndMaxPain = (e) => {
        //console.log("this is the value", e.target.value, instrumentId);
        dispatch(getStockPCRAndMaxPain("", e, instrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETSTOCKPCRANDMAXPAIN));
    }

    const getTickerData = (instrumentId) => {
        //console.log("this is the value in tickerData", instrumentId);
        dispatch(getTickerInfo("", instrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETTICKERINFO));
    }


    useEffect(() => {
        dispatch(getAllSignalsForExpert(closeSignalForExpert, user.id, user.email, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETALLSIGNALSFOREXPERTURL));
    }, [isSignalTableDataRefreshed]);

    useEffect(() => {
        //console.log("this is the value  in useeffect for tickerinfo", tickerInfo);
    }, [expiryDates, putCallRatio, tickerInfo]);


    if (isDashboardLoading === true) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    return (<DashboardLayout>
        <DashboardNavbar/>
        {alert}
        <MDBox py={3}>
            {/*//Total experts following - real time update*/}
            <MDTypography variant="h5" fontWeight="regular" mb={4}>
                Welcome back {user.name}. Here you will find -- all the information regarding your signals,
                followers, their performance, etc.<br/>
                YOLO Platform currently has {user.allExpertsOnPlatformCount} experts
                and {user.allFollowersOnPlatformCount} followers. A combined total of XX trade signals have been
                generated by experts on this platform to date.
            </MDTypography>
            <MDBox mt={1.5}>
                <Grid container spacing={3}>
                    {/*//Total followers - real time update*/}
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="person_add"
                                title={"Total Followers"}
                                count={user.followerCount}
                                percentage={{
                                    color: "success", amount: "+3%", label: "View Details",
                                }}
                            />
                        </MDBox>
                    </Grid>
                    {/*//Total trade signals*/}
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="person_add"
                                title={"Total Trade Signals Sent"}
                                count={dashboard.signals ? dashboard.signals.totalSignals : <div>Loading...</div>}
                                percentage={{
                                    color: "success", amount: "+3%", label: "View Details",
                                }}
                            />
                        </MDBox>
                    </Grid>
                    {/*% of signals closed in profit*/}
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="person_add"
                                title={" % of signals closed in profit"}
                                count={dashboard.signals ? dashboard.signals.signalsClosedInProfit :
                                    <div>Loading...</div>}
                                percentage={{
                                    color: "success", amount: "+3%", label: "View Details",
                                }}
                            />
                        </MDBox>
                    </Grid>
                    {/*$1000 invested with this expert would result in*/}
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="person_add"
                                title={"$1 invested at the start would now be"}
                                count={dashboard.signals ? "$" + dashboard.signals.dollarOneInvestedNowIs :
                                    <div>Loading...</div>}
                                percentage={{
                                    color: "success", amount: "+3%", label: "View Calculation",
                                }}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={8}>
                <Grid container spacing={1}>
                    {/*52 week performance on the basis of trade signals*/}
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                            <ReportsBarChart
                                color="info"
                                title="Trade Signals Performance"
                                description="55% profits realised"
                                date={"updated 4 minutes ago"}
                                chart={reportsBarChartData}
                            />
                        </MDBox>
                    </Grid>
                    {/*Trade Signals sent in the last year*/}
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                            <ReportsBarChart
                                color="info"
                                title="Trade Signals Generated"
                                description="Monthly # of Trade Signals"
                                date={"Last signal sent 2 days ago"}
                                chart={reportsBarChartData}
                            />
                        </MDBox>
                    </Grid>
                    {/*Followers interactions with Trade Signals*/}
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                            <ReportsLineChart
                                color="dark"
                                title="Followers executing Trade Signals"
                                description={"Total of all monthly Trade Signals executed by # of followers"}
                                date={"last signal executed 12 min. ago"}
                                chart={tasks}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container spacing={3}>
                {/*Generate New Trade Signal*/}

                {/*All Trade Signals*/}
                <Grid container item spacing={1}>
                    <Grid item xs={10}>
                        {fetchingAllSignals ? <div>Loading...</div> : closeSignalError ? <div>{closeSignalError}</div> :
                            <Card>
                                <MDBox p={1} lineHeight={1}>
                                    <MDTypography variant="h5" fontWeight="medium">
                                        Your Trade Signals
                                    </MDTypography>
                                </MDBox>
                                <MDBox py={1}>
                                    <DataTable
                                        table={signalTableData}
                                        pageIndexParent={pageIndex}
                                        setPageIndex={setPageIndex}
                                        canSearch
                                    />
                                </MDBox>
                                <MDTypography variant="h5"
                                              fontWeight="medium">{getAllSignalsError ? getAllSignalsError : ""}</MDTypography>
                            </Card>}
                    </Grid>
                    <Grid item xs={2}>
                        <MDBox p={3}>
                            <MDTypography variant="h5">Find Max Pain for Stock Options</MDTypography>
                            <MDBox mt={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={6}>
                                        <Tooltip
                                            title="What Stock Ticker do you want to find the Max Pain for?"
                                            placement="top">
                                            <MDInput variant="standard"
                                                     label="Enter Stock Ticker Symbol Here"
                                                     id="instrumentId"
                                                     inputProps={{onChange: (e) => handleChangeInstrumentId(e)}}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Tooltip
                                            title="What options expiry date for the stock ticker do you want to find the Max Pain for?"
                                            placement="top">
                                            <MDButton onClick={(e) => getStockOpExpiryDates(e)}>expiry
                                                dates</MDButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            {expiryDates.length > 0 ? <MDBox mt={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Tooltip
                                            title="Options Expiry Date to find Max Pain point for."
                                            placement="top">
                                            <MDBox mb={3}>
                                                <Autocomplete
                                                    options={expiryDates}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    renderInput={(params) => <MDInput {...params}
                                                                                      label="Expiry Date"
                                                                                      placeholder={expiryDate ? expiryDate : "select"}
                                                                                      variant="standard"/>}
                                                    onChange={(event, value) => {
                                                        /*                                                                    console.log(value);
                                                                                                                            console.log(event);*/
                                                        setExpiryDate(value);
                                                        getPCRAndMaxPain(value);
                                                    }}
                                                    /*autoComplete="off"*/
                                                />
                                            </MDBox>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={6}>
                                        Spot Price - {tickerInfo?tickerInfo.spotPrice:0}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        Dividend Yield - {tickerInfo&&tickerInfo.divYield?Number((tickerInfo.divYield*100).toFixed(3))+"%":0}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        Currency - {tickerInfo?tickerInfo.currency:""}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        Exchange - {tickerInfo?tickerInfo.exchange:0}
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <MDTypography variant="h5" fontWeight="light">
                                            Put Call Ratio - </MDTypography>
                                        <MDTypography variant="h5"
                                                      fontWeight="bold">{putCallRatio}</MDTypography>

                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <MDTypography variant="h5" fontWeight="light">
                                            Max Pain - </MDTypography>
                                        <MDTypography variant="h5" fontWeight="bold">{maxPain}
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MDTypography variant="h5" fontWeight="light">
                                            bufferLow - </MDTypography>
                                        <MDTypography variant="h5" fontWeight="bold">{bufferLow}
                                        </MDTypography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <MDTypography variant="h5" fontWeight="light">
                                            bufferHigh - </MDTypography>
                                        <MDTypography variant="h5" fontWeight="bold">{bufferHigh}
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox> : ""}
                        </MDBox>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>
        <Footer/>
    </DashboardLayout>);
}
