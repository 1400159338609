import {useState, forwardRef, useImperativeHandle} from "react";
// @material-ui/core components
import InputAdornment from '@mui/material/InputAdornment';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Lock from "@mui/icons-material/Lock";
import Face from "@mui/icons-material/Face";
import Key from "@mui/icons-material/Key";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

const BrokerDetails = forwardRef((props, ref) => {
    const [liveAccount, setLiveAccount] = useState(false);
    const [autoTradingEnabled, setAutoTradingEnabled] = useState(false);
    const [brokerId, setBrokerId] = useState("");
    const [spreadbetAccountId, setSpreadbetAccountId] = useState("");
    const [cfdAccountId, setCfdAccountId] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [apiUserName, setApiUserName] = useState("");
    const [apiPwd, setApiPwd] = useState("");
    const [stocksSharesAccountId, setStocksSharesAccountId] = useState("");
    const [brokerDetailsState, setBrokerDetailsState] = useState("");
    useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        }, sendState: () => {
            return sendState();
        }, state: {
            liveAccount,
            autoTradingEnabled,
            brokerId,
            spreadbetAccountId,
            cfdAccountId,
            apiKey,
            apiUserName,
            apiPwd,
            stocksSharesAccountId,
        },
    }));
    const sendState = () => {
        return {
            liveAccount,
            autoTradingEnabled,
            brokerId,
            spreadbetAccountId,
            cfdAccountId,
            apiKey,
            apiUserName,
            apiPwd,
            stocksSharesAccountId,
        };
    };
    const isValidated = () => {
        if (brokerId !== "0" && brokerId !== "") {
            if (spreadbetAccountId === "" && apiKey === "" && apiUserName === "" && apiPwd === "") {
                setBrokerDetailsState("error");
                return false;
            } else {
                setBrokerDetailsState("success");
                return true;
            }
        } else {
            setBrokerDetailsState("success");
            return true;
        }
    };

    const brokersDropdown = [{label: 'IG', id: 1}, {label: 'Robinhood', id: 2}, {
        label: 'Ameritrade', id: 3
    }, {label: 'Alpaca', id: 4}];
    return (<MDBox>
            <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                <MDBox mb={1}>
                    <MDTypography variant="h5" fontWeight="regular">
                        Your preferred trading broker
                    </MDTypography>
                </MDBox>
                <MDTypography variant="body2" color="text">
                    Entering your broker details here will allow you to auto trade based on the signals sent by the
                    expert traders you follow on this platform.
                </MDTypography>
            </MDBox>
            <Grid container justifyContent="center" spacing={3}>
                <Tooltip title="Account Type: For now we are only allowing Demo Account details to be entered."
                         placement="top">
                    <Grid item sx={{m: 1}}>
                        <MDBox mb={1}>
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={liveAccount}
                                    disabled
                                    /*onChange={handleChange}*/
                                    inputProps={{'aria-label': 'controlled'}}
                                />} label={liveAccount ? "Live Account" : "Demo Account"}/>
                            </FormGroup>
                        </MDBox>
                    </Grid>
                </Tooltip>
                <Grid item sx={{m: 1}}>
                    <MDBox mb={1}>
                        <Tooltip
                            title="Auto Trading settings for this broker. You can change these settings later too. This setting is disabled for now."
                            placement="top">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch
                                        checked={autoTradingEnabled}
                                        onChange={() => setAutoTradingEnabled(!autoTradingEnabled)}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />}
                                    label={autoTradingEnabled ? "Auto Trading Enabled" : "Auto Trading Disabled"}
                                />
                                {/*                            <MDTypography variant="caption" fontWeight="light">
                            </MDTypography>*/}
                            </FormGroup>
                        </Tooltip>
                    </MDBox>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={1}>
                <Grid container item justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                        <Tooltip
                            title="Select your preferred broker to place trades via YOLO's platform. For now only IG in the UK. More Brokers to be added soon."
                            placement="top">
                            <MDBox mb={3}>
                                <Autocomplete
                                    fullWidth={true}
                                    options={brokersDropdown}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <MDInput {...params} label="Select Broker" placeholder="IG"
                                                                      variant="standard"/>}
                                    onChange={(event, value) => {
                                        /*                      console.log(value.id);
                                                              console.log(event);*/
                                        setBrokerId(value.id);
                                    }}
                                    /*autoComplete="off"*/
                                />
                            </MDBox>
                        </Tooltip>{/*
                        <MDTypography variant="caption">
                            For now only IG in the UK. More Brokers to be added soon.
                        </MDTypography>*/}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <MDBox mb={3}>
                            <MDInput
                                fullWidth
                                success={brokerDetailsState === "success"}
                                error={brokerDetailsState === "error"}
                                id="apiKey"
                                label={<span>
                API Key {brokerId !== "0" && brokerId !== "" ? <small>(required)</small> : ""}
              </span>}
                                type="password"
                                onChange={(event) => {
                                    setApiKey(event.target.value);
                                }}
                                autoComplete="new-password"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Key/></InputAdornment>
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                        <MDBox mb={3}>
                            <MDInput
                                fullWidth
                                success={brokerDetailsState === "success"}
                                error={brokerDetailsState === "error"}
                                id="apiUserName"
                                label={<span>
                Broker API User Name {brokerId !== "0" && brokerId !== "" ? <small>(required)</small> : ""}
              </span>}
                                onChange={(event) => {
                                    setApiUserName(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Face/></InputAdornment>
                                }}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <MDBox mb={3}>
                            <MDInput
                                fullWidth
                                success={brokerDetailsState === "success"}
                                error={brokerDetailsState === "error"}
                                id="apiPwd"
                                label={<span>
                Broker API Password {brokerId !== "0" && brokerId !== "" ? <small>(required)</small> : ""}
              </span>}
                                type="password"
                                onChange={(event) => {
                                    setApiPwd(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Key/></InputAdornment>
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                        <MDBox mb={3}>
                            <MDInput
                                fullWidth
                                id="cfdAccountId"
                                label={<span>
                CFD Account ID
              </span>}
                                onChange={(event) => {
                                    setCfdAccountId(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Lock/></InputAdornment>
                                }}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={1}>
                <Grid container item spacing={1}>
                    <Grid item xs={6}>
                        <MDBox mb={3}>
                            <MDInput
                                fullWidth
                                id="stocksSharesAccountId"
                                label={<span>
                Spread Bet Account ID {brokerId !== "0" && brokerId !== "" ? <small>(required)</small> : ""}
              </span>}
                                onChange={(event) => {
                                    setSpreadbetAccountId(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Lock/></InputAdornment>
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                        <MDBox mb={3}>
                            <MDInput
                                fullWidth
                                success={brokerDetailsState === "success"}
                                error={brokerDetailsState === "error"}
                                id="spreadbetAccountId"
                                label={<span>
                Stocks and Shares Account ID
              </span>}
                                onChange={(event) => {
                                    setStocksSharesAccountId(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Lock/></InputAdornment>
                                }}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>

    );
});

BrokerDetails.displayName = "BrokerDetails";

export default BrokerDetails;
