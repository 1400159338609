//import { createBrowserHistory, History } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage';
import rootReducer from "reducers";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['loginState'] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
    reducer: pReducer,
});

let persistor = persistStore(store);

export {store, persistor};
