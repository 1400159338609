import * as constant from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  token: null,
  imageFileType: String,
  imageFileBytes: String,
  isFetching: true,
  isAuthenticated: false,
  user: {
    firstName: String, //new
    lastName: String, //new
    type: Number, //1 expert and 2 follower
    title: String,
    username: String,
    email: String,
    password: String,
    passwordConfirmation: String,
    countryOfResidence: String,
    contactNumber: Number,
    url: { type: String, default: String },
    image: { type: String, default: String },
    expert: {
      signals: Array,
      profitability: Number,
      tradeStrategy: Array, //1-ultra long term, 2-medium term, 3-short term, 4-ultra-short term
      tradeType: Array, //1-spreadbet, 2-options, 3-stocks/funds
      tradeInstruments: Array, //1-precious metals, 2- funds, 3-companies, 4-currencies
    },
    follower: {
      brokers: [{
        liveAccount: Boolean, //Is this a live account if not then its a demo account
        name: String,
        spreadbetAccountId: String,
        cfdAccountId: String,
        stocksSharesAccountId: String,
        autoTradingEnabled: Boolean,
        apiKey: String,
        apiUserName: String,
        apiPwd: String,
      }],
      tradeStrategyInterests: Array, //1-ultra long term, 2-long term, 3-medium term, 4-short term, 5-ultra-short term
      tradeInstrumentsInterests: Array, //1-precious metals, 2- funds, 3-companies, 4-currencies
      tradeTypeInterests: Array, //1-spreadbet, 2-options, 3-funds, 4-stocks  //new
      brokerId: Number, //1-IG, 2-RohinHood, 3-TorS, 4-Alpaca
      overallAutoTradingEnabled: Boolean,
      //TODO - Industry segment interests
    },
    description: { type: String, default: '' },
    followers: Array,
    following: Array,
    signalTradesObjectId: Object //connecting user's signals trades with their profile. this id connects to trades collection that specifies all the trades executed by follower.
  }
};
const registerState = (state = initialState, action) => {
  switch (action.type) {
    case constant.REGISTER_REQUEST: {
      return { ...state, isLoading: true };
    }

    case constant.REGISTER_FAILURE: {
      return { ...state, isLoading: false, error: action.error };
    }

    case constant.REGISTER_SUCCESS: {
      return {
        ...state,
        isLoading: true,
        token: action.token,
      };
    }
    case constant.RECEIVE_IMAGE_FILE: {
      return {
        ...state,
        imageFileType: action.imageFileType,
        imageFileBytes: action.imageFileBytes,
      };
    }

    default:
      return state;
  }
};
export default registerState;
