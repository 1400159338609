import * as constant from "./constants";
import {RECEIVE_STOCK_OPTIONS_STRATEGIES} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  closeSignalError: null,
  notifications: [],
  signalForFollower: {
    signalId: "",
    expertId: "",
    expertUserName: "",
    signalOriginatingMedium: "",
    signalOriginatingMediumId: "",
    signalInstrumentId: "",
    signalSuggestedTradeDirection: "",
    signalTraded: false,
    tradeStillOpen: false,
    signalIgnoredByFollower: false
  },
  allSignalsForFollower: [],
  signalForExpert: {
    signalId: "",
    expertId: "",
    expertName: "",
    instrumentId: "",
    instrumentName: "",
    originatingMediumMessageIdForOpening: "",
    originatingMediumMessageIdForClosing: "",
    spotPriceWhenSignalInitiated: 0,
    spotPriceWhenSignalClosed: 0,
    suggestedTradeDirection: "", //long or short
    signalTraded: false,
    tradeStillOpen: false,
    signalStillOpen: false,
    signalPerformance:0,
    optionsTrade: false,
    spreadBetTrade: false,
    simpleStocksTrade: false,
    coreTrade: false,
    speculativeTrade: false,
    signalIgnoredByFollower: false,
    spreadBetTradeSignal: {
      tradeDirection: "",
      suggestedSize: 0,
      signalSuggestedStopLossPrice: 0,
      signalSuggestedLimitPrice: 0,
      SignalSuggestedExecutionPrice: 0,
      guaranteedStopLoss: false
    },
  },
  allSignalsForExpert: [],
  expiryDates: [],
  putCallRatio: 0,
  bufferLow: 0,
  maxPain: 0,
  bufferHigh: 0,
  tickerInfo: [],
  lastUpdated: "",
  isChartLoading: false,
  optionStrategies:{},
};
const notificationsState = (state = initialState, action) => {
  switch (action.type) {
    case constant.REQUEST_NOTIFICATIONS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case constant.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    }

    case constant.RECEIVE_NOTIFICATIONS: {
      return {
        ...state,
        isLoading: false,
        notifications: action.notifications,
      };
    }
    case constant.RECEIVE_TICKER_INFO: {
      return {
        ...state,
        isLoading: false,
        tickerInfo: action.tickerInfo,
      };
    }
    case constant.RECEIVE_STOCK_OPTIONS_EXPIRY_DATE: {
      return {
        ...state,
        isLoading: false,
        expiryDates: action.expiryDates,
        putCallRatio: 0,
        maxPain: 0,
        bufferHigh: 0,
        bufferLow: 0
      };
    }

    case constant.RECEIVE_STOCK_OPTIONS_PCR_MAXPAIN: {
      return {
        ...state,
        isLoading: false,
        putCallRatio: action.putCallRatio,
        maxPain: action.maxPain,
        bufferHigh: action.bufferHigh,
        bufferLow: action.bufferLow
      };
    }

    case constant.REQUEST_ALL_EXPERT_SIGNALS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case constant.RECEIVE_ALL_EXPERT_SIGNALS: {
      return {
        ...state,
        isLoading: false,
        allSignalsForExpert: action.allSignalsForExpert,
        tickerInfo:[]
      };
    }

    case constant.REQUEST_CLOSE_EXISTING_SIGNAL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case constant.RECEIVE_CLOSE_EXISTING_SIGNAL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case constant.RECEIVE_CLOSE_EXISTING_SIGNAL_ERROR: {
      return {
        ...state,
        closeSignalError: action.closeSignalError,
      };
    }

    case constant.REQUEST_STOCK_OPTIONS_STRATEGIES: {
      return {
        ...state,
        isChartLoading: true,
      };
    }

    case constant.RECEIVE_STOCK_OPTIONS_STRATEGIES: {
      return {
        ...state,
        isChartLoading: false,
        optionStrategies: action.optionStrategies,
      };
    }


    default:
      return state;
  }
};
export default notificationsState;
