import {useEffect, useState, useContext} from "react";
import {history} from '_helpers/history';
import {useDispatch, useSelector} from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
//import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
/*import InputAdornment from "@mui/material/InputAdornment";
import MoneyIcon from "@mui/icons-material/Money";*/
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Close from "@mui/icons-material/Close";
import Ignore from "@mui/icons-material/ThumbDown";
import IgnoreUnfilled from "@mui/icons-material/ThumbDownOutlined";
import Open from "@mui/icons-material/Check";
import TradeConfirmation from "@mui/icons-material/InsertDriveFile";
import MaxPain from '@mui/icons-material/WbIncandescentOutlined';


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable from "yolo/components/DataTable";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "yolo/components/AdminLayout/DashboardLayout";
import DashboardNavbar from "yolo/components/AdminLayout/Navbars/DashboardNavbar";
import Footer from "yolo/components/AdminLayout/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data!ami
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

import {
    toggleOverallAutoTradingOptionAction,
    getAllSignalsForFollower,
    executeTradeSignal,
    closeTradeSignal,
    getTradeConfirmationDetails,
    localUpdateSignalDataRow,
    updateFollowerSignalPreferences,
    getInstrumentDetailsForBroker,
    /*newSignalAlert*/
} from "../../middleware/followerDashboard/actions";
import {
    unIgnoreSignal,
    ignoreSignal,
    getStockOptionsExpiryDates,
    getStockPCRAndMaxPain,
    getTickerInfo,
    getOptionsStrategies
} from "yolo/middleware/notifications/actions";

import {WebSocketContext} from 'yolo/components/WebSocket';
import Card from "@mui/material/Card";
//import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import {Link} from "@mui/material";
import {position} from "stylis";

import Charts from "../charts/Charts";


//import io from "socket.io-client";


export default function FollowerDashboard() {
    const [alert] = useState(null);
    const dispatch = useDispatch();
    const {sales, tasks} = reportsLineChartData;
    const ws = useContext(WebSocketContext);
    const useDashboard = useSelector((state) => ({
        isDashboardLoading: state.followerDashboardState.isLoading,
        fetchingAllSignals: state.followerDashboardState.fetchingAllSignals,
        isNotificationLoading: state.notificationsState.isLoading,
        error: state.followerDashboardState.error,
        getAllSignalsError: state.followerDashboardState.getAllSignalsError,
        dashboard: state.followerDashboardState.dashboard,
        user: state.loginState.user,
        allSignalsForFollower: state.followerDashboardState.allSignalsForFollower,
        signalTableHeads: state.followerDashboardState.signalTableHeads,
        signalTableData: state.followerDashboardState.signalTableData,
        signalTableDataRows: state.followerDashboardState.signalTableDataRows,
        tradeConfirmationDetails: state.followerDashboardState.tradeConfirmationDetails,
        instrumentDetailsFromBroker: state.followerDashboardState.instrumentDetailsFromBroker,
        loadingExecutionDialogModal: state.followerDashboardState.loadingExecutionDialogModal,
        expiryDates: state.notificationsState.expiryDates,
        putCallRatio: state.notificationsState.putCallRatio,
        bufferLow: state.notificationsState.bufferLow,
        maxPain: state.notificationsState.maxPain,
        bufferHigh: state.notificationsState.bufferHigh,
        tickerInfo: state.notificationsState.tickerInfo,
    }));
    const {
        isDashboardLoading,
        tradeConfirmationDetails,
        fetchingAllSignals,
        error,
        user,
        dashboard,
        signalTableData,
        getAllSignalsError,
        signalTableDataRows,
        instrumentDetailsFromBroker,
        loadingExecutionDialogModal,
        expiryDates,
        putCallRatio,
        bufferLow,
        bufferHigh,
        tickerInfo,
        maxPain
    } = useDashboard;
    const [isSignalTableDataRefreshed, setIsSignalTableDataRefreshed] = useState(false);
    const [open, setOpen] = useState("");
    const [openMaxPain, setOpenMaxPain] = useState("");
    const [tickerData, setTickerData] = useState([]);
    const [openExecutionDialog, setOpenExecutionDialog] = useState("");
    const [pageIndex, setPageIndex] = useState(0);
    const initiateFollowerTradePreferencesState = {
        followerSuggestedExecutionPrice: 0,
        signalId: "",
        followerSuggestedSignalRisk: 0,
        followerSuggestedExecutionQty: 0,
        minDealSize: 0,
        followerSuggestedStopLoss: 0,
        followerSuggestedLimit: 0,
        spreadBet: false,
        stocksShares: false,
        options: false,
        followerSuggestedBrokerAccountId: "",
        followerSuggestedBrokerId: 0
    }
    const [expiryDate, setExpiryDate] = useState("");
    const [followerTradePreferences, setFollowerTradePreferences] = useState(initiateFollowerTradePreferencesState);
    //const { followerSuggestedExecutionPrice, followerSuggestedExecutionQty, followerSuggestedStopLoss, followerSuggestedLimit, spreadBet, options, followerSuggestedSignalRisk, followerSuggestedBrokerAccountId, followerSuggestedBrokerId, stocksShares } = followerTradePreferences;

    const handlePreferencesChange = (id, value) => {
        /*const { id, value } = e.target;*/
        setFollowerTradePreferences(prevState => ({...prevState, [id]: value}));
    }

    const handleOpen = (signalId, userEmail, tradeId, signalSuggestedTradeDirection, signalInstrumentId) => {
        //console.log("this is opening the modal");
        //ws.sendMessage("testingMess123");
        getTradeConfirmation(signalId, tradeId, user.email);
    };
    const getOptionStrategiesForTickerAndDirection = (signalId, userEmail, signalSuggestedTradeDirection, signalInstrumentId) => {
        //console.log("this is opening the modal");
        //ws.sendMessage("testingMess123");
        //dispatch(getOptionsStrategies(signalId, userEmail, signalSuggestedTradeDirection, signalInstrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETIRONCONDOROPTIONSTRADEVALUES));
        dispatch(getOptionsStrategies(signalId, userEmail, signalSuggestedTradeDirection, signalInstrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETOPTIONCHAINFORSYMBOL));
            /*.then(() => {
                getTradeConfirmation(signalId, tradeId, user.email);
            }
            );*/
    };
    const handleOpenMaxPain = (signalId, userEmail, instrumentId) => {
        //console.log("this is opening the modal", signalId, instrumentId);
        getTickerData(signalId, userEmail, instrumentId);
        getStockOpExpiryDates(signalId, userEmail, instrumentId);
    };

    const handleOpenExecutionDialog = (signalId, instrumentId, signalSuggestedTradeDirection, signalInstrumentId) => {
        //reset the follower preferences state
        getTickerData(signalId, user.Email, instrumentId);
        getOptionStrategiesForTickerAndDirection(signalId, user.Email, signalSuggestedTradeDirection, signalInstrumentId);
        dispatch(getInstrumentDetailsForBroker(signalId, instrumentId, user.email, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETINSTRUMENTDETAILSFORBROKER))
            .then(() => {
                    //console.log("this is the response from instrument details from broker", instrumentDetailsFromBroker);
                    setFollowerTradePreferences(initiateFollowerTradePreferencesState);
                    setOpenExecutionDialog(signalId);
                }
            );
    };

    const handleClose = () => {
        setOpenExecutionDialog("1");
        setOpen("1");
        setOpenMaxPain("1");
        setExpiryDate("");
    };

    const [overallAutoTradingEnabled, setOverallAutoTradingEnabled] = useState(user.user.follower.overallAutoTradingEnabled);
    const closeTradeForSignal = (signalId, followerId, dataRow) => {
        dispatch(closeTradeSignal(signalId, followerId, user.email, dataRow, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_CLOSETRADESIGNAL))
            .then(() => {
                    //console.log("the closing of trade signal is complete. Now refresh data");
                    setIsSignalTableDataRefreshed(!isSignalTableDataRefreshed);
                    /*refreshSignalData();*/
                }
            );
    }
    const toggleOverallAutoTradingOption = () => {
        dispatch(toggleOverallAutoTradingOptionAction(user.email, !overallAutoTradingEnabled, user.id, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_TOGGLEAUTOTRADEOPTION))
            .then(() => {
                    setOverallAutoTradingEnabled(!overallAutoTradingEnabled);
                }
            );
    }
    const getTradeConfirmation = (signalId, tradeId, followerEmail) => {
        dispatch(getTradeConfirmationDetails(tradeId, followerEmail, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETDEALCONFIRMATION))
            .then(() => {
                    setOpen(signalId);
                }
            );
    }

    const getStockOpExpiryDates = (signalId, followerEmail, instrumentId) => {
        dispatch(getStockOptionsExpiryDates(signalId, followerEmail, instrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETSTOCKOPTIONSEXPIRYDATES))
            .then(() => {
                setOpenMaxPain(signalId);
            });
    }

    const getTickerData = (signalId, followerEmail, instrumentId) => {
        dispatch(getTickerInfo(followerEmail, instrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETTICKERINFO));
        //    .then(() => {
        //    setOpenMaxPain(signalId);
        //});
    }

    const getPCRAndMaxPain = (signalId, expiryDate, instrumentId) => {
        dispatch(getStockPCRAndMaxPain(signalId, expiryDate, instrumentId, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETSTOCKPCRANDMAXPAIN))
            .then(() => {
                setOpenMaxPain(signalId);
            });
    }
    const executeTradeForSignal = (signalId) => {
        dispatch(executeTradeSignal(signalId, user.id, user.email, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_EXECUTETRADESIGNAL))
            .then(() => {
                    setIsSignalTableDataRefreshed(!isSignalTableDataRefreshed);
                }
            );
    }
    const ignoreSignalFunction = (signalId, followerId) => {
        dispatch(ignoreSignal(signalId, followerId, user.email, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_IGNORESIGNALFORFOLLOWER))
            .then(() => {
                    setIsSignalTableDataRefreshed(!isSignalTableDataRefreshed);
                }
            );
    }
    const unIgnoreSignalFunction = (signalId, followerId) => {
        dispatch(unIgnoreSignal(signalId, followerId, user.email, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_UNIGNORESIGNALFORFOLLOWER))
            .then(() => {
                    setIsSignalTableDataRefreshed(!isSignalTableDataRefreshed);
                }
            );
    }

    const updatePreferencesAndExecuteTrade = (signalId) => {
        setFollowerTradePreferences(inputs => ({...inputs, ['spreadBet']: true}));
        setFollowerTradePreferences(inputs => ({...inputs, ['signalId']: signalId}));
        //console.log("follower preferences", followerTradePreferences, followerId, dataRow);
    }

    const goToAllExperts = (e) => {
        e.preventDefault();
        history.navigate("/admin/all-experts");
    }

    const styleMaxPainModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const riskDropdown = [{label: 'Not Sure', id: 0}, {label: 'Very Low', id: 1}, {label: 'Low', id: 2}, {label: 'Medium', id: 3}, {label: 'High', id: 4},  {label: 'Very High', id: 5}];

    const updateDataRows = (signalTableDataRowsFromAction) => {
        let data = [];
        signalTableDataRowsFromAction.map((prop, key) => {
            let dataRow = {
                id: key,
                originDate: prop[0],
                signalInstrumentId: prop[1],
                signalSuggestedTradeDirection: prop[2],
                signalStatus: prop[11] === true ? "Active" : "Inactive",
                expertName: prop[3],
                signalId: prop[5],
                expertId: prop[6],
                tradeStillOpen: prop[7],
                signalTraded: prop[8],
                signalIgnoredByFollower: prop[9] === true ? "Ignored" : "Not Ignored",
                tradeId: prop[10] === "" ? "Signal Trade Not Executed" : prop[10],
                signalStillOpen: prop[11],
                tradePriceLevelWhenOpen: prop[8] ? prop[12] : "Not Traded",
                tradePriceLevelWhenClosed: !prop[8] ? "Not Traded" : prop[7] ? "Trade Still Open" : prop[13],
                tradeConfirmationStatus: prop[14],
                profitOfTradeWhenClosed: !prop[8] ? "Not Traded" : prop[7] ? "Trade Still Open" : prop[15],
                sizeOfTradeWhenOpen: !prop[8] ? "Not Traded" : prop[16],
                signalSuggestedExecutionPrice: prop[17],
                signalSuggestedLimitPrice: prop[18] > 0 ? prop[18] : "TBC",
                signalSuggestedStopLossPrice: prop[19] > 0 ? prop[19] : "TBC",
                actions: (
                    // we've added some custom button actions
                    <MDBox key={key}>
                        {/* use this button to open execution dialog box */}
                        {prop[11] && !prop[9] && !prop[8] ?
                            <>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Trade this Signal"
                                    placement="bottom"
                                >
                                    <IconButton
                                        onClick={() => {
                                            handleOpenExecutionDialog(dataRow.signalId, dataRow.signalInstrumentId, dataRow.signalSuggestedTradeDirection, dataRow.signalInstrumentId);
                                        }}
                                        size={"small"}
                                        color="warning"
                                    >
                                        <Open/>
                                    </IconButton>
                                </Tooltip>
                                <Dialog
                                    open={prop[5] === openExecutionDialog}
                                    onClose={handleClose}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth="lg"
                                    //fullWidth
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 1000,
                                    }}
                                >
                                    <DialogTitle id="form-dialog-title">Trade Execution Instruction for {prop[1]}</DialogTitle>
                                    <DialogContent>
                                        {loadingExecutionDialogModal ? <CircularProgress disableShrink/> :
                                            <>
                                                <DialogContentText justifyContent={"center"} justifyItems={"center"} justifySelf={"center"}>
                                                    {instrumentDetailsFromBroker && instrumentDetailsFromBroker.spreadBetTradeable ? "Please confirm trade instructions for this signal -" : "This instrument is deemed NOT TRADEABLE under your broker's spread betting account rules"}
                                                </DialogContentText>
                                                <Divider></Divider>
                                                <form noValidate>
                                                    <Grid container justifyContent="center">
                                                        <Grid item xs={12} sm={5}>
                                                            <Tooltip
                                                                title="Your Risk Appetite"
                                                                placement="top">
                                                                <MDBox mb={3}>
                                                                    <Autocomplete
                                                                        options={riskDropdown}
                                                                        id={"followerSuggestedSignalRisk"}
                                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                        renderInput={(params) => <MDInput {...params} label="Risk Appetite" placeholder="Not Sure"
                                                                                                          variant="standard"/>}
                                                                        onChange={(event, value) => {
                                                                            handlePreferencesChange(value, value);
                                                                        }}
                                                                        /*autoComplete="off"*/
                                                                    />
                                                                </MDBox>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider></Divider>
                                                    <Grid container justifyContent={"space-between"} spacing={1} wrap={"nowrap"}>
                                                    <Grid item xs={6} >
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item xs={12} sm={5}>
                                                            <Tooltip
                                                                title="Trade Ticker Symbol"
                                                                placement="top">
                                                            <MDInput
                                                                autoFocus
                                                                margin="dense"
                                                                label={"Trade Ticker Symbol"}
                                                                inputProps={{
                                                                    onChange: (e) => handlePreferencesChange(e.target.id, e.target.value),
                                                                    id: "instrumentId",
                                                                    name: "instrumentId",
                                                                    defaultValue: prop[1],
                                                                    disabled: true,
                                                                    /*endAdornment: (
                                                                        <InputAdornment position="end">
                                                                          <BusinessIcon className={classes.inputAdornmentIcon} />
                                                                        </InputAdornment>
                                                                    ),*/
                                                                }}
                                                            />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <Tooltip
                                                                title="Trade Ticker Name"
                                                                placement="top">
                                                            <MDInput
                                                                autoFocus
                                                                margin="dense"
                                                                label={"Trade Ticker Name"}
                                                                inputProps={{
                                                                    onChange: (e) => handlePreferencesChange(e.target.id, e.target.value),
                                                                    id: "instrumentName",
                                                                    name: "instrumentName",
                                                                    defaultValue: instrumentDetailsFromBroker ? instrumentDetailsFromBroker.instrumentName : "",
                                                                    disabled: true,
                                                                    /*endAdornment: (
                                                                        <InputAdornment position="end">
                                                                          <BusinessIcon className={classes.inputAdornmentIcon} />
                                                                        </InputAdornment>
                                                                    ),*/
                                                                }}
                                                            />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item xs={12} sm={5}>
                                                            <Tooltip
                                                                title="Suggested Trade Direction"
                                                                placement="top">
                                                            <MDInput
                                                                autoFocus
                                                                margin="dense"
                                                                label={"Suggested Trade Direction"}
                                                                inputProps={{
                                                                    onChange: (e) => handlePreferencesChange(e.target.id, e.target.value),
                                                                    id: "signalSuggestedTradeDirection",
                                                                    name: "signalSuggestedTradeDirection",
                                                                    defaultValue: prop[2],
                                                                    disabled: true,
                                                                    /*endAdornment: (
                                                                        <InputAdornment position="end">
                                                                          <BusinessIcon className={classes.inputAdornmentIcon} />
                                                                        </InputAdornment>
                                                                    ),*/
                                                                }}
                                                            />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <Tooltip
                                                                title="Suggested Execution Price"
                                                                placement="top">
                                                            <MDInput
                                                                autoFocus
                                                                margin="dense"
                                                                label={"Suggested Execution Price"}
                                                                inputProps={{
                                                                    onChange: (e) => handlePreferencesChange(e.target.id, e.target.value),
                                                                    id: "followerSuggestedExecutionPrice",
                                                                    name: "followerSuggestedExecutionPrice",
                                                                    defaultValue: prop[17],
                                                                    /*endAdornment: (
                                                                        <InputAdornment position="end">
                                                                          <MoneyIcon className={classes.inputAdornmentIcon} />
                                                                        </InputAdornment>
                                                                    ),*/
                                                                    type: "number",
                                                                }}
                                                            />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item xs={5}>
                                                            <Tooltip
                                                                title="Suggested Deal Size"
                                                                placement="top">
                                                            <MDInput
                                                                autoFocus
                                                                margin="dense"
                                                                label={"Suggested Deal Size"}
                                                                inputProps={{
                                                                    id: "followerSuggestedExecutionQty",
                                                                    name: "followerSuggestedExecutionQty",
                                                                    onChange: (e) => handlePreferencesChange(e.target.id, e.target.value),
                                                                    defaultValue: instrumentDetailsFromBroker && instrumentDetailsFromBroker.dealingRules ? instrumentDetailsFromBroker.dealingRules.minDealSize : 0,
                                                                    /*endAdornment: (
                                                                        <InputAdornment position="end">
                                                                          <MoneyIcon className={classes.inputAdornmentIcon} />
                                                                        </InputAdornment>
                                                                    ),*/
                                                                    type: "number",
                                                                    disabled: !(instrumentDetailsFromBroker && instrumentDetailsFromBroker.spreadBetTradeable),
                                                                }}
                                                            />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="space-between" spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Tooltip
                                                                title="Suggested Limit Price"
                                                                placement="top">
                                                            <MDInput
                                                                autoFocus
                                                                margin="dense"
                                                                label={"Suggested Limit Price"}
                                                                helperText={prop[18] > 0 ? "A limit price was suggested in the signal. Change it as needed." : "No Limit Suggested by Signal. Please enter a limit price for this trade."}
                                                                inputProps={{
                                                                    id: "followerSuggestedLimit",
                                                                    name: "followerSuggestedLimit",
                                                                    onChange: (e) => handlePreferencesChange(e.target.id, e.target.value),
                                                                    defaultValue: prop[18],
                                                                    /*endAdornment: (
                                                                        <InputAdornment position="end">
                                                                          <MoneyIcon className={classes.inputAdornmentIcon} />
                                                                        </InputAdornment>
                                                                    ),*/
                                                                    type: "number",
                                                                    autoComplete: "off",
                                                                }}
                                                            />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Tooltip
                                                                title="Suggested Stop Loss Price"
                                                                placement="top">
                                                            <MDInput
                                                                autoFocus
                                                                margin="dense"
                                                                label={"Suggested Stop Loss Price"}
                                                                helperText={prop[19] > 0 ? "A stop loss price was suggested in the signal. Change it as needed." : "No Stop Loss Suggested by Signal. Please enter a SL price for this trade."}
                                                                inputProps={{
                                                                    id: "followerSuggestedStopLoss",
                                                                    name: "followerSuggestedStopLoss",
                                                                    onChange: (e) => {
                                                                        handlePreferencesChange(e.target.id, e.target.value);
                                                                    },
                                                                    defaultValue: prop[19],
                                                                    /*endAdornment: (
                                                                        <InputAdornment position="end">
                                                                          <MoneyIcon />
                                                                        </InputAdornment>
                                                                    ),*/
                                                                    type: "number",
                                                                    autoComplete: "off",
                                                                }}
                                                            />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Charts/>
                                                    </Grid>
                                                    </Grid>
                                                </form>
                                            </>
                                        }
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container spacing={5} justifyItems="center" justifySelf={"center"} justifyContent={"center"} style={{
                                            margin: 0,
                                            width: '100%',
                                        }}>
                                            <Grid item xs={3}>
                                                <Tooltip
                                                    title="Cancel this trade execution and return back to main page"
                                                    placement="top">
                                                <MDButton
                                                    onClick={handleClose}
                                                    color="primary">
                                                    Cancel
                                                </MDButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip
                                                    title="Update Trade Preferences and Execute Trade on your Broker's platform"
                                                    placement="top">
                                                    <span>
                                                <MDButton
                                                    disabled={!(instrumentDetailsFromBroker && instrumentDetailsFromBroker.spreadBetTradeable)}
                                                    onClick={() => updatePreferencesAndExecuteTrade(dataRow.signalId)}
                                                    color="primary">
                                                    Execute Trade
                                                </MDButton>
                                                        </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </Dialog>
                            </>
                            : ""}
                        {/* use this button to get trade confirmation */}
                        {prop[8] ?
                            <>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Get Trade Confirmation"
                                    placement="bottom"
                                >
                                    <IconButton
                                        onClick={() => {
                                            handleOpen(dataRow.signalId, user.id, dataRow.tradeId, dataRow.signalSuggestedTradeDirection, dataRow.signalInstrumentId);
                                        }}
                                        color="warning"
                                        size={"small"}
                                    >
                                        <TradeConfirmation/>
                                    </IconButton>
                                </Tooltip>
                                {/*{tradeConfModal(prop[5], user.id, prop[10])}*/}
                                <Dialog
                                    open={prop[5] === open}
                                    onClose={handleClose}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth="lg"
                                    //fullWidth
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 1000,
                                    }}
                                >
                                    <DialogTitle id="form-dialog-title">{tradeConfirmationDetails ? "Trade Execution Details for " + tradeConfirmationDetails.marketData.instrumentName : "No Trade Confirmation Found"}</DialogTitle>
                                    <DialogContent>
                                        {!tradeConfirmationDetails ?
                                            <span id="transition-modal-description">Please check on your broker portal
                                </span>                                            :
                                            <>
                                                <DialogContentText justifyContent={"center"} justifyItems={"center"} justifySelf={"center"}>
                                                    This is the trade confirmation detail for {user.name} -
                                                </DialogContentText>
                                                <Divider></Divider>
                                                    <Grid container justifyContent="center">
                                                        <Grid item xs={12} sm={5}>
                                  Trade Size - {tradeConfirmationDetails ? tradeConfirmationDetails.position.size : 0}
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                  Trade Execution Price - {tradeConfirmationDetails ? tradeConfirmationDetails.position.level : 0}
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                  Trade Stop Loss - {tradeConfirmationDetails && tradeConfirmationDetails.position.stopLevel > 0 ? tradeConfirmationDetails.position.stopLevel : "Not Set"}
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                  Trade Limit Level - {tradeConfirmationDetails && tradeConfirmationDetails.position.limitLevel > 0 ? tradeConfirmationDetails.position.limitLevel : "Not Set"}
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                  Execution Date - {tradeConfirmationDetails ? tradeConfirmationDetails.position.createdDate : 0}
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                  Trade Direction - {tradeConfirmationDetails ? tradeConfirmationDetails.position.direction : 0}
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                  Trade Currency - {tradeConfirmationDetails ? tradeConfirmationDetails.position.currency : 0}
                                </Grid>
                                                    </Grid>
                                                </>}
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container justifyItems="center" justifySelf={"center"} justifyContent={"center"} style={{
                                            margin: 0,
                                            width: '100%',
                                        }}>
                                            <Grid item xs={3}>
                                                <Tooltip
                                                    title="Close"
                                                    placement="top">
                                                    <span>
                                                <MDButton
                                                    onClick={() => handleClose()}
                                                    color="primary">
                                                    OK
                                                </MDButton>
                                                        </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </Dialog>
                            </>
                            : ""}
                        {/* use this button to close the trade */}
                        {prop[7] ?
                            <Tooltip
                                id="tooltip-top"
                                title="Close this Trade"
                                placement="bottom"
                            >
                                <IconButton
                                    onClick={() => {
                                        closeTradeForSignal(dataRow.signalId, user.id, dataRow.signalInstrumentId);
                                    }}
                                    color="warning"
                                    size={"small"}
                                >
                                    <Close/>
                                </IconButton>
                            </Tooltip> : ""}
                        {/* use this button to undislike or dislike the signal */}
                        {prop[9] ?
                            <Tooltip
                                id="tooltip-top"
                                title="Un-dislike this Signal"
                                placement="bottom"
                            >
                                <IconButton
                                    onClick={() => {
                                        unIgnoreSignalFunction(dataRow.signalId, user.id);
                                    }}
                                    color="warning"
                                    size={"small"}
                                >
                                    <Ignore/>
                                </IconButton>
                            </Tooltip>
                            : <Tooltip
                                id="tooltip-top"
                                title="Dislike this Signal"
                                placement="bottom"
                            >
                                <IconButton
                                    onClick={() => {
                                        ignoreSignalFunction(dataRow.signalId, user.id);
                                    }}
                                    color="warning"
                                    size={"small"}
                                >
                                    <IgnoreUnfilled/>
                                </IconButton>
                            </Tooltip>}
                        {/* use this button to find maxpain for the recommended instrument id */}
                        <Tooltip
                            id="tooltip-top"
                            title="Useful data for Ticker"/*+{dataRow.signalInstrumentId}*/
                            placement="bottom"
                        >
                            <IconButton
                                onClick={() => handleOpenMaxPain(dataRow.signalId, user.id, dataRow.signalInstrumentId)}
                                color="warning"
                                size={"small"}
                            >
                                <MaxPain/>
                            </IconButton>
                        </Tooltip>
                        <>
                        <Dialog
                            open={prop[5] === openMaxPain}
                            onClose={handleClose}
                            color={"info"}
                            aria-labelledby="form-dialog-title"
                            maxWidth="lg"
                            //fullWidth
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 1000,
                            }}
                        >
                            <DialogTitle id="form-dialog-title"> {"Useful Market data for " + prop[1]}</DialogTitle>
                            <DialogContent>
                                <DialogContentText justifyContent={"center"} justifyItems={"center"} justifySelf={"center"}>
                                    This is the trade confirmation detail for {user.name} -
                                </DialogContentText>
                                <Divider></Divider>
                                <Grid container justify="center">
                                    <Grid item xs={6} sm={6}>
                                        Spot Price - {tickerInfo?tickerInfo.spotPrice:0}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        Dividend Yield - {tickerInfo&&tickerInfo.divYield?Number((tickerInfo.divYield*100).toFixed(3))+"%":0}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        Currency - {tickerInfo?tickerInfo.currency:""}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        Exchange - {tickerInfo?tickerInfo.exchange:0}
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container justify="center">
                                    <Grid item>
                                        {expiryDates ? "Max Pain and Put Call Ratio Details for Stock Options of Ticker " + prop[1] : "No Expiry Dates Found for Stock Option Ticker" + prop[1]}
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container justifySelf="center" justifyItems={"center"} justifyContent={"center"} spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        {!expiryDates ?
                                            <MDTypography id="transition-modal-description" sx={{mt: 2}}>No expiry dates
                                                recovered for stock options trading for the Ticker
                                            </MDTypography>
                                            :
                                            <Tooltip
                                                title={"Select the options expiry date to find out Max Pain and Put Call Ratio for - " + prop[1]}
                                                placement="top">
                                                <Autocomplete
                                                    id="expiryDates"
                                                    options={expiryDates}     // we want to work in controlled mode
                                                    renderInput={(params) => <MDInput {...params}
                                                                                      label="Expiry Date"
                                                                                      placeholder={expiryDate ? expiryDate : "select"}
                                                                                      variant="standard"/>}
                                                    onChange={(event, value) => {
                                                        /*                                                                    console.log(value);
                                                                                                                            console.log(event);*/
                                                        setExpiryDate(value);
                                                        getPCRAndMaxPain(dataRow.signalId, value, dataRow.signalInstrumentId);
                                                    }}
                                                >
                                                </Autocomplete>
                                            </Tooltip>
                                        }
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container justify="center">
                                    <Grid item xs={6} sm={6}>
                                        Put Call Ratio - {putCallRatio}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        Max Pain - {maxPain}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        bufferLow - {bufferLow}
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        bufferHigh - {bufferHigh}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                        </>
                    </MDBox>
                )
            }
            data.push(dataRow);
        });
        return data;
    }

    useEffect(() => {
        /*dispatch(getAllSignalsForFollower(user.email, user.id, updateDataRows, cookies));*/
        if (open !== "" || openExecutionDialog !== "" || openMaxPain !== "") {
            dispatch(localUpdateSignalDataRow(updateDataRows, signalTableDataRows));
        }
    }, [open, openExecutionDialog, loadingExecutionDialogModal, instrumentDetailsFromBroker, openMaxPain]);

    useEffect(() => {
        /*dispatch(getAllSignalsForFollower(user.email, user.id, updateDataRows, cookies));*/
        if (open !== "" || openExecutionDialog !== "" || openMaxPain !== "") {
            dispatch(localUpdateSignalDataRow(updateDataRows, signalTableDataRows));
        }
    }, [expiryDate, maxPain]);

    useEffect(() => {
        /*dispatch(getAllSignalsForFollower(user.email, user.id, updateDataRows, cookies));*/
            //console.log("this is ticker info data", tickerInfo);
            setTickerData(tickerInfo);
    }, [tickerInfo]);

    useEffect(() => {
        dispatch(getAllSignalsForFollower(user.email, user.id, updateDataRows, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_GETALLSIGNALSFORFOLLOWERURL));
    }, [isSignalTableDataRefreshed]);

    /*  useEffect(()=> {
        setFollowerTradePreferences(initiateFollowerTradePreferencesState => ({...initiateFollowerTradePreferencesState, ["minDealSize"]:instrumentDetailsFromBroker && instrumentDetailsFromBroker.dealingRules?instrumentDetailsFromBroker.dealingRules.minDealSize:0}));
        //setFollowerTradePreferences(prevState =>  ({ ...prevState, ["minDealSize"]: instrumentDetailsFromBroker?instrumentDetailsFromBroker.dealingRules.minDealSize:123 }));
      }, [instrumentDetailsFromBroker]);*/


    useEffect(() => {
        if (followerTradePreferences.signalId !== "") {
            dispatch(updateFollowerSignalPreferences(followerTradePreferences.signalId, user.id, user.email, followerTradePreferences, process.env.REACT_APP_PROXY_ADDRESS, process.env.REACT_APP_UPDATEFOLLOWERPREFERENCESTOSIGNAL)).then(() => {
                //TODO - if error in updating signal preferences, then show the error on dialog screen dont just refresh signaltabledata.
                executeTradeForSignal(followerTradePreferences.signalId);
            });
        }
    }, [followerTradePreferences]);


    const experts = <Link className="orange-link" to="/dashboard/all-experts">Experts</Link>
    if (isDashboardLoading === true) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            {alert}
            <MDBox py={3}>
                {/*//Total experts following - real time update*/}
                <MDTypography variant="h5" fontWeight="regular" mb={4}>
                    Welcome back {user.name}. Here you will find all the information regarding the experts you are
                    following, their trade signals, your trades, their performances, etc.<br/>
                    YOLO Platform currently has {user.allExpertsOnPlatformCount} experts
                    and {user.allFollowersOnPlatformCount} followers. A combined total of XX trade signals have been
                    generated by experts on this platform to date.
                </MDTypography>
                <MDBox mt={1.5}>
                    <Grid container spacing={3}>
                        {/*//Total experts following - real time update*/}
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="person_add"
                                    title={"Experts you are Following"}
                                    count={user.user.following.length + " of " + user.allExpertsOnPlatformCount + " experts"}
                                    percentage={{
                                        color: "success",
                                        amount: "+1",
                                        label: "than last week",
                                    }}
                                />
                                {/*<a href="#pablo" onClick={(e) => goToAllExperts(e)}>
                                    Experts
                                </a>*/}
                            </MDBox>
                        </Grid>
                        {/*//Executed trade signals v/s All Signals*/}
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="Total Signals Received"
                                    count={user.totalTradeSignalsReceivedCount}
                                    percentage={{
                                        color: "success",
                                        amount: "+3%",
                                        label: "compared to last month",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        {/*% of signals closed in profit*/}
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="% of signals closed in profit"
                                    count="65%`"
                                    percentage={{
                                        color: "success",
                                        amount: "+7%",
                                        label: "compared to last month",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        {/*$1 invested would now be --- */}
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="$1 invested at the start would now be"
                                    count="$1.4"
                                    percentage={{
                                        color: "success",
                                        amount: "+$0.2",
                                        label: "than last week - If all received expert trade signals were executed",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                {/*Signals performance of the experts following in last 1 year*/}
                <MDBox mt={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsBarChart
                                    color="info"
                                    title="All Experts Signals Performance"
                                    description="performance of all signals from all experts you follow in the last 12 months"
                                    date={"Total" + user.totalTradeSignalsReceivedCount + " signals received from " + user.expertsFollowingCount + " experts"}
                                    chart={reportsBarChartData}
                                />
                            </MDBox>
                        </Grid>
                        {/*All signals executed performance of the follower in last 1 year*/}
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsLineChart
                                    color="success"
                                    title="All Executed Trades Performance"
                                    description={
                                        <>
                                            Performance of those signals that were executed.
                                        </>
                                    }
                                    date={"On average each execution has resulted in 15% increase"}
                                    chart={sales}
                                />
                            </MDBox>
                        </Grid>
                        {/*Total trades executed per month/day*/}
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsLineChart
                                    color="dark"
                                    title="Trades Signals Executed"
                                    description={"On average you have executed 12 per day"}
                                    date={"last signal executed 12 min. ago"}
                                    chart={tasks}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                {/*All Trade Signals*/}
                {fetchingAllSignals ?
                    <Card>
                        <MDBox p={3} lineHeight={1}>
                            <MDTypography variant="h5" fontWeight="medium">
                                Loading...
                            </MDTypography>
                            <MDTypography variant="button" color="text">
                                Please wait while we fetch all your signals...
                            </MDTypography>
                        </MDBox>
                    </Card> :
                    <Card>
                        <MDBox mt={1} justifyContent="space-between" alignItems="center">
                            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                                <Grid item xs={12} md={10} lg={8}>
                                    <MDBox display="flex" alignItems="center" lineHeight={1} p={1}>
                                            <MDTypography variant="h5" fontWeight="medium">
                                                Your Trade Signals
                                            </MDTypography>
                                    </MDBox>
                                </Grid>
                                <Grid item xs={6} md={4} lg={2}>
                                    {/*<MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>*/}
                                    <MDBox display="flex" alignItems="center" lineHeight={1} p={1}>
                                            <MDTypography variant="h5">{overallAutoTradingEnabled?"Auto Trading Enabled":"Auto Trading Disabled"}</MDTypography>
                                            <Switch checked={overallAutoTradingEnabled} onChange={toggleOverallAutoTradingOption} />
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox py={1}>
                            <DataTable
                                table={signalTableData}
                                pageIndexParent = {pageIndex}
                                setPageIndex = {setPageIndex}
                                canSearch
                            />
                        </MDBox>
                        <MDTypography variant="h5"
                                      fontWeight="medium">{getAllSignalsError ? getAllSignalsError : ""}</MDTypography>
                    </Card>
                }
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}
